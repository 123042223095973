import { type StringDictionary } from 'src/shared/types/general';

/** Receive a string and convert first letters of each word to uppercase and the rest to lowercase */
export function toPascalCase(unformattedWord?: string) {
  if (unformattedWord?.length) {
    const formattedWord = unformattedWord.replace(/\w+/g, (w) => {
      if (w && w[0]) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      }
      return '';
    });
    return formattedWord;
  }
  return unformattedWord;
}

/**
 * Converts an array of strings to a comma-separated string.
 *
 * @param {string[]} arr - The array of strings to convert.
 *
 * @returns {string} A comma-separated string.
 *
 * @example const arr = ['google', 'facebook', 'internal'];
 * const str = convertArrayToString(arr);
 * console.log(str); // Output: 'google, facebook, internal'
 */
export function toCommaString(arr: string[]) {
  return arr.join(', ');
}

/** Genera un ID único para cada petición, basado en el tiempo actual y un número aleatorio. */
export function uniqueId() {
  // eslint-disable-next-line no-bitwise
  const timestamp = ((Date.now() / 1000) | 0).toString(16);
  const id =
    timestamp +
    Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, '0');
  return id;
}

/** Remplaza texto con formato de template string
 * @example
 * const originalText= "Some text targeted to ${audience} in ${count}"
 * const newText= stringTemplatesReplacer(originalText, {audience: 'Students', count: '25 states'}); // "Some text targeted to Students in 25 states"
 */
export function stringTemplatesReplacer(originalText: string, templates: StringDictionary) {
  const refKeys = Object.keys(templates) as Array<keyof StringDictionary>;
  let newText = originalText;
  refKeys.forEach((key) => {
    const value = String(templates[key]);
    const regex = new RegExp(`\\$\\{${String(key)}\\}`, 'g');
    newText = newText.replace(regex, value);
  });
  return newText;
}

/**
 * Formatea una cadena de número de teléfono, eliminando todos los caracteres no numéricos
 * y devolviendo el número de teléfono en el formato (xxx) xxx-xxxx.
 *
 * @param {string} phoneNumberString - La cadena de caracteres del número de teléfono a formatear.
 * @returns {string|null} El número de teléfono formateado, o null si no se pudo formatear.
 *
 * @example
 * // Devuelve "(123) 456-7890"
 * formatPhoneNumber("123-456-7890");
 *
 * @example
 * // Devuelve null
 * formatPhoneNumber("invalid number");
 */
export function formatPhoneNumber(phoneNumberString?: string) {
  // Comprueba si phoneNumberString es un valor válido
  if (!phoneNumberString) {
    return null;
  }

  // Limpia la cadena de caracteres eliminando todos los caracteres no numéricos
  const cleaned = phoneNumberString.replace(/\D/g, '');

  // Intenta hacer coincidir la cadena de caracteres con el patrón deseado
  const match = cleaned.match(/^(\d{1,3})(\d{2})(\d{4})(\d{4})$/);

  // Si hay una coincidencia, formatea el número de teléfono y devuélvelo
  if (match) {
    return `(+${match[1]}) ${match[2]}-${match[3]}-${match[4]}`;
  }

  // Si no hay coincidencia, devuelve null
  return phoneNumberString;
}

/**
 * Elimina los diacríticos (acentos) de una cadena de texto dada.
 *
 * @param {string} str - La cadena de texto de la cual se eliminarán los diacríticos.
 * @returns {string} - La cadena de texto sin diacríticos.
 *
 * @example
 * const cadenaConAcentos = 'áéíóúñ';
 * const cadenaSinAcentos = removeDiacritics(cadenaConAcentos);
 * console.log(cadenaSinAcentos); // 'aeioun'
 */
export function removeDiacritics(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
