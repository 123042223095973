// ---Dependencies
import { type ReactElement } from 'react';
// ---UI Dependencies
// ---Custom Hooks
// ---Config
// ---Assets
// ---Utils
import { useSelectorBuilder } from 'src/utils/hooks/styles/useSelectorBuilder';
// ---Requests
// ---Styles
import style from './SimpleDivider.module.scss';
// ---CommonComponents
// ---Components
// ---Redux/Zustand
// ---Services

interface Props {
  variant?: 'three' | 'blue' | 'pink' | 'darkGrey' | 'white'; //| 'asTheme' | 'oppositeTheme';
  type?: 'horizontal' | 'vertical';
}

/**
 * SimpleDivider Component:  Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function SimpleDivider({ type = 'horizontal', variant = 'pink' }: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { classNames } = useSelectorBuilder(style.SimpleDivider);
  // -----------------------MAIN METHODS
  // -----------------------AUX METHODS
  // -----------------------RENDER
  return <div className={`${classNames} ${style[`SimpleDivider-${type}-${variant}`]} `} />;
}
