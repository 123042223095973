// ---Dependencys
import { type ReactElement } from 'react';
import { type IconType } from 'react-icons';

// ---Props Types
interface Props {
  icon: IconType;
  className?: string;
}

/** Contiene el componente icono de react-icons dentro de un div para controlar el icono con más precisión, para estilizar el icono apunta a el svg dentro del div */
export function ReactIcon(props: Props): ReactElement {
  const { icon: Icon, ...className } = props;
  return (
    <div {...className}>
      <Icon />
    </div>
  );
}
