// ---Dependencies
import { type ReactElement } from 'react';
// ---Styles
import style from './WhatsButtonFloat.module.scss';
import { FloatButton } from 'antd';
import { useSelectorBuilder } from 'src/utils/hooks/styles/useSelectorBuilder';
import { WhatsAppOutlined } from '@ant-design/icons';
import { useSocialInfo } from 'src/utils/hooks/app/useSocialInfo';

/**
 * WhatsButtonFloat Component:  Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function WhatsButtonFloat(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { classNames } = useSelectorBuilder(style.WhatsButtonFloat);
  const { whatsApp } = useSocialInfo();

  return (
    <FloatButton
      href={whatsApp.url}
      target="_blank"
      icon={<WhatsAppOutlined />}
      className={classNames}
    />
  );
}
