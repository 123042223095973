// ---Dependencies
import { type ReactElement, useEffect } from 'react';
import {
  type CredentialResponse,
  googleLogout,
  type TokenResponse,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from '@react-oauth/google';
// ---Locales
// ---Custom Hooks
import {
  type GoogleDecodedInfo,
  type GoogleJwtDecodedInfo,
  useGoogleDecode,
} from './config/useGoogleDecode';
import Image from 'next/image';
import { GEN_ALT } from 'AppConfig/globalConfig';
// ---Styles
import styles from './GoogleSsoStyles.module.scss';
import { useNavbarLocales } from 'src/GlobalLayout/NavbarSelector/Navbar/locales/useNavbarLocales';

type GoogleHookResponse = Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>;

/**
 * GoogleSso Component:  Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function GoogleSso({
  onSuccess,
}: {
  onSuccess: (_?: GoogleDecodedInfo | GoogleJwtDecodedInfo) => Promise<void>;
}): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  useGoogleOneTapLogin({ onSuccess: responseOneTap }); // Promptea la autenticacion
  const { googleDecode, jwtDecode, data } = useGoogleDecode();
  const loginFromHook = useGoogleLogin({ onSuccess: responseGoogleHook });
  const { googleSso } = useNavbarLocales().NavLogin;
  useEffect(() => {
    googleLogout();
  }, []);
  useEffect(() => {
    onSuccess(data);
  }, [data]);
  // -----------------------MAIN METHODS
  /** */
  function responseOneTap(response: CredentialResponse) {
    const credential = response?.credential;
    jwtDecode(credential);
  }
  /** */
  function responseGoogleHook(response: GoogleHookResponse) {
    const token = response?.access_token;
    googleDecode(token);
  }
  // -----------------------AUX METHODS
  // -----------------------RENDER
  return (
    <button type="button" onClick={() => loginFromHook()} className={styles.GoogleSso}>
      <Image
        src="/images/icons/iconGoogle.png"
        alt={GEN_ALT}
        width={24}
        height={24}
        style={{ aspectRatio: 'auto' }}
      />
      <span>{googleSso}</span>
    </button>
  );
}
