// ---Dependencies
import { type ReactElement } from 'react';
import { Mobile } from './Mobile/Mobile';
import { Desktop } from './Desktop/Desktop';
import { useAppInfoStoreV3 } from '@Redux/appInfo/store';

/**
 * Navbar Component: Ejemplo de navbar para la aplicación
 * @returns {ReactElement} ReactElement
 */
export function Navbar(): ReactElement | null {
  const { isMovil } = useAppInfoStoreV3(['isMovil']);
  return <>{isMovil ? <Mobile /> : <Desktop />}</>;
}
