// ---Dependencies
import { type ReactElement } from 'react';
// ---UI Dependencies
import { Button, Divider } from 'antd';
import { Fcol, Frow } from 'react-forge-grid';
// ---Locales
import { useNavbarLocales } from '../../../locales/useNavbarLocales';
// ---Components
import GoogleAuth from 'GlobalProvider/GoogleAuth/GoogleAuth';
import { GoogleSso } from './GoogleSso/GoogleSso';
import { useFormLogic } from './LoginForm/config/useFormLogic';
import { LoginForm } from './LoginForm/LoginForm';
import { useControlLoginModal } from 'Utils/hooks/app/useControlLoginModal';
import { useLocaleLink } from 'Utils/hooks/app/useLocaleLink';
import { useRouter } from 'next/router';
import {
  type GoogleDecodedInfo,
  type GoogleJwtDecodedInfo,
} from './GoogleSso/config/useGoogleDecode';
import { uniqueId } from 'Shared/utils/functions/stringUtils';
import { useCommonLocales } from 'src/appConfig/locales/common/useCommonLocales';

/**
 * NavLoginForm Component:  Descripcion del comportamiento...
 * @returns {ReactElement}
 */
export function NavLoginForm(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { notSignYet, forgotPass } = useNavbarLocales().NavLogin;
  const { orSimple } = useCommonLocales();
  const { setShowFalse, setScreenTypeValue } = useControlLoginModal();
  const formik = useFormLogic({ addToSubmit: setShowFalse });
  const localeLinks = useLocaleLink('/signup');
  const router = useRouter();
  // -----------------------MAIN METHODS
  /** */
  function navigateToSignup() {
    router.push({
      pathname: localeLinks?.['/signup'],
    });
    setShowFalse();
  }
  /** */
  function navigateToRecovery() {
    setScreenTypeValue('recovery');
  }
  /** Función que se activa cuando se recibe una respuesta correcta de google */
  async function onGoogleSuccess(data?: GoogleDecodedInfo | GoogleJwtDecodedInfo) {
    if (data) {
      const pass = uniqueId();
      await formik.setFieldValue('email', data.email);
      await formik.setFieldValue('password', pass);
      await formik.setFieldValue('authType', 'google');
      await formik.setFieldValue('fullName', data.name);
      setTimeout(() => formik.submitForm(), 100); // Add a small delay
    }
  }

  // -----------------------RENDER
  return (
    <GoogleAuth>
      <Frow vSpace={5} hAlign="center">
        <Fcol span={100}>
          <LoginForm formik={formik} />
        </Fcol>
        {/* <Fcol span={80}>
            <FacebookSso />
          </Fcol> */}
        <Fcol span={80}>
          <GoogleSso onSuccess={onGoogleSuccess} />
        </Fcol>
        <Fcol span={80}>
          <Button onClick={navigateToSignup} type="link">
            {notSignYet}
          </Button>
          <Divider>{orSimple}</Divider>
          <Button onClick={navigateToRecovery} type="link">
            {forgotPass}
          </Button>
        </Fcol>
      </Frow>
    </GoogleAuth>
  );
}
