// --- Dependency´s
import { type ReactElement, type ReactNode } from 'react';
import { useAppListener } from './config/useAppListener/useAppListener';
import AntdProvider from './AntdProvider/AntdProvider';
import { GoogleTagManager } from './GoogleTagManager/GoogleTagManager';

// import dynamic from 'next/dynamic';
// // ---Custom Hooks
// import { useAppListener } from './config/useAppListener/useAppListener';
// // ---Components
// import { LoadingFullScreen } from 'GlobalLayout/LoadingFullScreen/LoadingFullScreen';

// // ---Providers
// const GoogleAuth = dynamic(() => import('./GoogleAuth/GoogleAuth'), {
//   ssr: false,
//   loading: () => <LoadingFullScreen isLoading />,
// });

// const AntdProvider = dynamic(() => import('./AntdProvider/AntdProvider'), {
//   ssr: false,
//   loading: () => <LoadingFullScreen isLoading />,
// });

// -----------------------PROPS
interface Props {
  children: ReactNode;
}
/**
 * GlobalProvider Component: Componente que se representa globalmente en la aplicación y
 * persistir en todas las páginas. Puede agregar nuevos componentes aquí para representarlos
 * globalmente para Ex: Barras de herramientas, pies de página, componentes de chat, carritos, etc.
 * @param {Props} - Props of the component
 * @return {ReactElement} ReactElement
 */
export function GlobalProvider({ children }: Props): ReactElement {
  useAppListener();

  // -----------------------RENDER
  return (
    <GoogleTagManager>
      <AntdProvider>{children}</AntdProvider>
    </GoogleTagManager>
  );
}
