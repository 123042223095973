/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import { httpBatchLink, splitLink } from '@trpc/client';
import { createTRPCNext, WithTRPCSSROptions } from '@trpc/next';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import { transformer } from 'Shared/tRPC/transformer';


import { type AppRouter } from 'src/server/tRPC/root';
import { getAuthToken } from 'Utils/functions/localStorageUtils';
import { NextPageContext } from 'next';
import { ISupportedLanguage } from 'src/shared/constants/language';

const getBaseUrl = () => {
  if (typeof window !== 'undefined') return ''; // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

function setHeaders(locale: ISupportedLanguage, ctx?: NextPageContext) {
  const headers = ctx?.req?.headers
  const token = getAuthToken();
  return {
    ...headers,
    authorization: token ? `Bearer ${token}` : '',
    locale,
  };
}

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter, SSRContext>({
  config(opt) {
    const ctx = opt?.ctx;

    return {
      /**
       * Transformer used for data de-serialization from the server.
       *
       * @see https://trpc.io/docs/data-transformers
       */
      transformer,

      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       */
      links: [

        splitLink({
          condition(op) {
            // check for context property `skipBatch`
            return op.context?.locale === 'spanish';
          },
          // when condition is true, use normal request
          true: httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
            headers: () => {
              return setHeaders('spanish', ctx);
            },
          }),
          // when condition is false, use batching
          false: httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
            headers: () => {
              return setHeaders('english', ctx);
            },
          }),
        }),
      ],
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
  /**
   * Set headers or status code when doing SSR
   */
});

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;

/**
 * Extend `NextPageContext` with meta data that can be picked up by `responseMeta()` when server-side rendering
 */
export interface SSRContext extends NextPageContext {
  /**
   * Set HTTP Status code
   * @example
   * const utils = trpc.useContext();
   * if (utils.ssrContext) {
   *   utils.ssrContext.status = 404;
   * }
   */
  status?: number;
}

type MetaFunction = Required<WithTRPCSSROptions<AppRouter>>['responseMeta'];

type MetaOption = Parameters<MetaFunction>[0];