// ---Dependencies
import { useAppInfoStoreV3 } from '@Redux/appInfo/store';
import { type AppInfoInitial } from 'src/redux/appInfo/storeTypes';
// ---UI Dependencies
// ---Custom Hooks
// ---Config
// ---Assets
// ---Utils
// ---Requests
// ---Styles
// ---CommonComponents
// ---Components

/**
 * useControlLoginModal Custom Hook: Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function useControlLoginModal() {
  // -----------------------CONSTS, HOOKS, STATES
  const { showLogin, loginScreenType, patch } = useAppInfoStoreV3(['showLogin', 'loginScreenType']);
  // -----------------------MAIN METHODS
  /** */
  function setShowTrue() {
    patch({ showLogin: true });
  }
  /** */
  function setShowFalse() {
    patch({ showLogin: false });
  }
  /** */
  function setScreenTypeValue(params: AppInfoInitial['loginScreenType']) {
    patch({ loginScreenType: params });
  }
  // -----------------------AUX METHODS
  // -----------------------HOOK DATA
  return { value: showLogin, loginScreenType, setShowTrue, setShowFalse, setScreenTypeValue };
}
