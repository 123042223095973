// ---Dependencies
import { useRouter } from 'next/router';
import { useEffect } from 'react';
// ---Custom Hooks
import { useLocaleLink } from 'src/utils/hooks/app/useLocaleLink';
import { useGetLocal } from 'src/utils/hooks/app/useGetLocal';
import { useGlobalLoading } from 'src/utils/hooks/app/useGlobalLoading';
// ---Config
import { useTrpcLocale } from 'src/server/tRPC/config/useTrpcLocale';
import { swalApiError } from 'src/utils/functions/alertsUtils';
import { api } from '../config/api';
// ---Utils
import { setAuthToken } from 'Utils/functions/localStorageUtils';
// ---Redux
import { useUserInfoStore } from 'src/redux/userInfo/store';
import { isAdminPath, isPlatformPath } from 'src/shared/utils/functions/regex';

/** Mutación para activar petición para login */
export function useMutationLogIn() {
  // ---Extra functionality
  const router = useRouter();
  const currentPath = router.route;
  const isAdmin = isAdminPath.test(currentPath);
  const isPrivate = isPlatformPath.test(currentPath);
  const redirections = useLocaleLink('/my-forge');
  const { patch } = useUserInfoStore();
  // --Utility Hooks
  const localeConfig = useTrpcLocale();
  const { full } = useGetLocal();
  // --Call services
  const { setLoadingValue, setLoadingFalse } = useGlobalLoading();
  const { mutate, data, error, isLoading } = api.auth.logIn.useMutation(localeConfig);
  // --Response Events
  useEffect(() => {
    loadingInRedux();
    return setLoadingFalse;
  }, [isLoading]);
  useEffect(() => {
    withError();
    return setLoadingFalse;
  }, [error]);
  useEffect(() => {
    onServiceResponse();
    return setLoadingFalse;
  }, [data]);

  // ----------------MAIN METHODS
  /** Se activa cuando el "procedure" carga */
  function loadingInRedux() {
    setLoadingValue(isLoading);
  }
  /** Se activa cuando el endpoint de tRCP responde un error*/
  function withError() {
    if (error) {
      setLoadingFalse();
      const message = error?.message;
      swalApiError(full, message);
    }
  }
  /** Se activa cuando el "procedure" responde */
  function onServiceResponse() {
    if (data) {
      const token = data?.currentSession as string;
      setAuthToken(token);
      patch({
        basicInfo: {
          id: data._id,
          email: data.email,
          fullName: data.fullName,
          phone: data.phone,
        },
        credentials: {
          grantAccess: true,
          role: data.role,
        },
      });
      if (!isPrivate && !isAdmin) {
        router.push({
          pathname: redirections?.['/my-forge'],
        });
      }
    }
  }
  return { mutate, data };
}
