// ---Dependencies
import { useEffect } from 'react';
import { useTrpcLocale } from 'src/server/tRPC/config/useTrpcLocale';
// ---Custom Hooks
import { useGetLocal } from 'src/utils/hooks/app/useGetLocal';
import { useGlobalLoading } from 'src/utils/hooks/app/useGlobalLoading';
// ---Config
import { swalApiError, swalApiSuccessAuto } from 'src/utils/functions/alertsUtils';
import { api } from '../config/api';
import { useControlLoginModal } from 'src/utils/hooks/app/useControlLoginModal';
import { type FormikProps } from 'formik';
import { useNavbarLocales } from 'src/GlobalLayout/NavbarSelector/Navbar/locales/useNavbarLocales';
import { type IRecoveryForm } from 'src/GlobalLayout/NavbarSelector/Navbar/common/NavLogWrapper/NavLoginRecovery/RecoveryForm/config/definitions';

/** Mutación para solicitar recuperar contraseña */
export function useMutationRequestRecovery(formik: FormikProps<IRecoveryForm>) {
  // --Utility Hooks
  const { setShowFalse, setScreenTypeValue } = useControlLoginModal();
  const localeConfig = useTrpcLocale();
  const { full } = useGetLocal();
  const { successSwal } = useNavbarLocales().NavLoginRecovery;
  // --Call services
  const { setLoadingValue, setLoadingFalse } = useGlobalLoading();
  const { mutate, data, error, isLoading } = api.auth.requestRecovery.useMutation(localeConfig);
  // --Response Events
  useEffect(() => {
    loadingInRedux();
    return setLoadingFalse;
  }, [isLoading]);
  useEffect(() => {
    withError();
    return setLoadingFalse;
  }, [error]);
  useEffect(() => {
    onServiceResponse();
    return setLoadingFalse;
  }, [data]);
  // ----------------MAIN METHODS
  /** Se activa cuando el "procedure" carga */
  function loadingInRedux() {
    setLoadingValue(isLoading);
  }
  /** Se activa cuando el endpoint de tRCP responde un error*/
  function withError() {
    if (error) {
      setLoadingFalse();
      const message = error?.message;
      swalApiError(full, message);
    }
  }
  /** Se activa cuando el "procedure" responde */
  function onServiceResponse() {
    if (data) {
      swalApiSuccessAuto(full, successSwal);
      formik.resetForm();
      setScreenTypeValue('login');
      setShowFalse();
    }
  }
  return { mutate, data };
}
