export const locales = {
  english: {
    title: 'How do you prefer to contact us?',
    message: {
      INTERESTED:
        'Hello!\nExcited to innovate with ForgeMyTech!\n\nI would like to know more about ...',
    },
  },
  spanish: {
    title: '¿Cómo prefieres contactarnos?',
    message: {
      INTERESTED:
        '¡Hola!\n¡Emocionado por innovar con ForgeMyTech!\n\nMe gustaría saber más sobre ...',
    },
  },
} as const;

export type LocaleData = (typeof locales)['english'];
