// ---Dependencies
import { type ReactElement } from 'react';
import { Fcol, Frow } from 'react-forge-grid';
import { ForgeBrandThemed } from 'src/common/ForgeBrandThemed/ForgeBrandThemed';
import { LinkCustom } from 'src/common/LinkCustom/LinkCustom';
import { useUserInfoStore } from 'src/redux/userInfo/store';
import { propSizePicker } from 'src/utils/functions/responsiveUtils';

// ---Utils
import { useSelectorBuilder } from 'src/utils/hooks/styles/useSelectorBuilder';
import { ThemeSwitch } from '../../Navbar/common/ThemeSwitch/ThemeSwitch';
import { useMyForgeNavbarLocales } from '../locales/useMyForgeNavbarLocales';
// ---Styles
import style from './Desktop.module.scss';
import { NavButton } from '../common/NavButton/NavButton';
import { ProfileButton } from '../common/ProfileButton/ProfileButton';
import { LangSelector } from '../../Navbar/common/LangSelector/LangSelector';

/**
 * Desktop Component:  Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function Desktop(): ReactElement | null {
  // -----------------------CONSTS, HOOKS, STATES
  const { classNames } = useSelectorBuilder(style.Desktop);
  const { credentials } = useUserInfoStore(['credentials']);
  const { Buttons } = useMyForgeNavbarLocales();

  // -----------------------MAIN METHODS
  // -----------------------AUX METHODS
  // -----------------------RENDER
  if (credentials?.role === 'NORMAL')
    return (
      <nav>
        <Frow vAlign="middle" hSpace={0} className={classNames}>
          <Fcol {...propSizePicker({ md: 14 }, 12)}>
            <LinkCustom href="/">
              <ForgeBrandThemed className="forge-spark-icon" />
            </LinkCustom>
          </Fcol>
          <Fcol {...propSizePicker({ xs: 50, md: 62 }, 64)}>
            <Frow vAlign="middle" hAlign="center" hSpace={5}>
              <NavButton path="/" {...propSizePicker({ md: 18 }, 18)}>
                {Buttons.home}
              </NavButton>

              <NavButton path="/my-forge" {...propSizePicker({ md: 18 }, 18)}>
                {Buttons.summary}
              </NavButton>
            </Frow>
          </Fcol>
          <Fcol {...propSizePicker({ md: 5 }, 5)}>
            <ThemeSwitch />
          </Fcol>
          <Fcol {...propSizePicker({ md: 12 }, 9)}>
            <LangSelector />
          </Fcol>
          <Fcol {...propSizePicker({ md: 7 }, 9)}>
            <ProfileButton />
          </Fcol>
        </Frow>
      </nav>
    );
  return null;
}
