// ---Custom Hooks
import { useGetLocal } from 'Utils/hooks/app/useGetLocal';
// ---Config
import { FormikHelpers, useFormik } from 'formik';
import { yupValidation } from './yupValidation';
// ---Services
import { useMutationLogIn } from 'src/services/auth/useMutationLogIn';
import { InternalLogin } from 'src/server/auth/declarations';



interface Props {
  addToSubmit?: (_?: unknown) => void;
}
/**
 * useFormLogic Custom Hook: Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function useFormLogic({ addToSubmit }: Props) {
  // -----------------------CONSTS, HOOKS, STATES
  // ---Form Stuff
  const locale = useGetLocal().full;
  const initialValues: InternalLogin = {
    email: '',
    password: '',
    authType: 'internal',
  };
  const formik = useFormik<InternalLogin>({
    initialValues,
    validationSchema: yupValidation(locale),
    onSubmit,
  });
  // -----------------------Call Services
  // const apiUtils = api.useContext() usar para resetear queries
  const { mutate } = useMutationLogIn()
  // -----------------------MAIN METHODS
  async function onSubmit(values: InternalLogin, _: FormikHelpers<InternalLogin>) {
    if (addToSubmit) {
      addToSubmit();
    }
    mutate(values)
    formik.resetForm();
  }
  // -----------------------HOOK DATA
  return formik;
}
