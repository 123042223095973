import { useAppInfoStoreV3 } from 'src/redux/appInfo/store';
import { useGetLocal } from './useGetLocal';
import { SOCIAL_V2 } from 'src/appConfig/globalConfig';

/**
 * useSocialInfo Custom Hook: Devuelve toda la información de redes sociales acorde al dispositivo e idioma
 */
export function useSocialInfo() {
  // -----------------------CONSTS, HOOKS, STATES
  const { full } = useGetLocal();
  const { isMovil } = useAppInfoStoreV3(['isMovil']);
  const urlType = isMovil ? 'mobileUrl' : 'desktopUrl';
  const controlledInfo = {
    simpleContact: SOCIAL_V2[full].simpleContact,
    whatsApp: { ...SOCIAL_V2[full].whatsApp, url: SOCIAL_V2[full].whatsApp[urlType] },
    discord: { ...SOCIAL_V2[full].discord, url: SOCIAL_V2[full].discord[urlType] },
    facebook: { ...SOCIAL_V2[full].facebook, url: SOCIAL_V2[full].facebook[urlType] },
    instagram: { ...SOCIAL_V2[full].instagram, url: SOCIAL_V2[full].instagram[urlType] },
    telegram: { ...SOCIAL_V2[full].telegram, url: SOCIAL_V2[full].telegram[urlType] },
    tiktok: { ...SOCIAL_V2[full].tiktok, url: SOCIAL_V2[full].tiktok[urlType] },
    youtube: { ...SOCIAL_V2[full].youtube, url: SOCIAL_V2[full].youtube[urlType] },
    twitter: { ...SOCIAL_V2[full].twitter, url: SOCIAL_V2[full].twitter[urlType] },
  };
  // -----------------------HOOK DATA
  return controlledInfo;
}
