// ---Dependencys
import { useUserPrefStore } from '@Redux/userPref/store';
import { GEN_ALT } from 'AppConfig/globalConfig';
import Image from 'next/image';
import { type ReactElement } from 'react';

// ---Props Types
interface Props {
  className?: string;
  inverted?: boolean;
}

/** Contiene el tag ForgeBrandThemed dentro de un div para controlar la imagen con más precisión, setea un alt por defecto en caso de no contar con uno */
export function ForgeBrandThemed(props: Props): ReactElement {
  const { inverted, className } = props;
  const { theme } = useUserPrefStore(['theme']);
  const comparation = inverted ? theme !== 'dark' : theme === 'dark';
  const src = comparation
    ? '/images/icons/forge-spark-dark.webp'
    : '/images/icons/forge-spark-light.webp';
  return (
    <div className={`ForgeBrandThemed ${className || ''}`}>
      <Image src={src} alt={GEN_ALT} width={96} height={32} style={{ aspectRatio: 'auto' }} />
    </div>
  );
}
