// ---Dependencies
import { useRouter } from 'next/router';
import { type ReactElement } from 'react';
import { useUserInfoStore } from 'src/redux/userInfo/store';
import { isAdminPath, isPlatformPath } from 'src/shared/utils/functions/regex';
import { AdminNavbar } from './AdminNavbar/AdminNavbar';
import { MyForgeNavbar } from './MyForgeNavbar/MyForgeNavbar';
import { Navbar } from './Navbar/Navbar';

/**
 * NavbarSelector Component: Selecciona la navbar que corresponda
 * @returns {ReactElement}
 */
export function NavbarSelector(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const router = useRouter();
  const currentPath = router.asPath;
  const { credentials } = useUserInfoStore(['credentials']);
  const isAdmin = isAdminPath.test(currentPath);
  const isPlatform = isPlatformPath.test(currentPath);
  const isAuth = credentials?.role === 'NORMAL';

  // -----------------------RENDER
  if (isAdmin) return <AdminNavbar />;
  if (isPlatform && isAuth) return <MyForgeNavbar />;
  return <Navbar />;
}
