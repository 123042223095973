export const locales = {
  english: {
    Buttons: {
      home: 'Home',
      summary: 'Summary',
    },
    signOut: 'Sign Out',
    myAccount: 'My Account',
    modal: {
      loginTittle: 'Log in',
      recoveryPass: 'Password Recovery',
    },
  },
  spanish: {
    Buttons: {
      home: 'Inicio',
      myAccount: 'Mi Cuenta',
      summary: 'Resumen',
    },
    signOut: 'Salir',
    myAccount: 'Mi Cuenta',
    modal: {
      loginTittle: 'Log in',
      recoveryPass: 'Password Recovery',
    },
  },
} as const;

export type LocaleData = (typeof locales)['english'];
