// ---Dependencies
import { type ReactElement } from 'react';
import { Desktop } from './Desktop/Desktop';
import { useAppInfoStoreV3 } from '@Redux/appInfo/store';
import { Mobile } from './Mobile/Mobile';

/**
 * MyForgeNavbar Component: Ejemplo de MyForgeNavbar para la aplicación
 * @returns {ReactElement} ReactElement
 */
export function MyForgeNavbar(): ReactElement | null {
  const { isMovil } = useAppInfoStoreV3(['isMovil']);
  return <>{isMovil ? <Mobile /> : <Desktop />}</>;
}
