// ---Dependencys
import Image from 'next/image';
import { type ReactElement } from 'react';
// ---Utils
import { useSelectorBuilder } from 'Utils/hooks/styles/useSelectorBuilder';
import { GEN_ALT } from 'AppConfig/globalConfig';
import { useCommonLocales } from 'AppConfig/locales/common/useCommonLocales';
import { useAppInfoStoreV3 } from '@Redux/appInfo/store';
// ---Style
import style from './LoadingFullScreen.module.scss';

interface Props {
  isLoading?: boolean;
}
/**
 * LoadingFullScreen Component: Componente de carga global, escucha el isLoading global y pinta un spinner mientras este activo, recibe isLoading prop para debugear estilos de spinner
 * @returns {ReactElement} ReactElement
 */
export function LoadingFullScreen({ isLoading: propLoading }: Props): ReactElement | null {
  // -----------------------CONSTS, HOOKS, STATES
  const { loadingLabel } = useCommonLocales().LoadingFullScreen;
  const { isLoading: realIsLoading } = useAppInfoStoreV3(['isLoading']);
  const loadingText = `${loadingLabel.toUpperCase()}...`;
  const isLoading = propLoading || realIsLoading;
  const { classNames } = useSelectorBuilder(style.LoadingFullScreen);
  // -----------------------RENDER
  if (isLoading) {
    return (
      <div className={classNames}>
        <Image
          alt={GEN_ALT}
          src="/images/icons/just-logo.webp"
          width={250}
          height={250}
          style={{ aspectRatio: 'auto' }}
        />
        <h2>{loadingText}</h2>
      </div>
    );
  }
  return null;
}
