// ---Dependencies
import { Divider } from 'antd';
import { type ReactElement } from 'react';
import { Fcol, Frow } from 'react-forge-grid';
import {
  FaDiscord,
  FaFacebookSquare,
  FaInstagram,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { ForgeBrandThemed } from 'src/common/ForgeBrandThemed/ForgeBrandThemed';
import { LinkCustom } from 'src/common/LinkCustom/LinkCustom';
import { COMPANY_YEAR } from 'src/shared/constants/basicConstants';
import { boolResponsiveProps, responsiveBasicGrid } from 'src/utils/functions/responsiveUtils';
import { useSelectorBuilder } from 'Utils/hooks/styles/useSelectorBuilder';
import { useNavFooterLocales } from './locales/useNavFooterLocales';
// ---Styles
import styles from './NavFooter.module.scss';
import { useSocialInfo } from 'src/utils/hooks/app/useSocialInfo';

/**
 * Navbar Component: Ejemplo de Footer para la aplicación
 * @returns {ReactElement} ReactElement
 */
export function NavFooter(): ReactElement {
  const { classNames } = useSelectorBuilder(styles.NavFooter);
  const { faqLink, helpLink, contactLink, privPolLink, serviceTermsLink } = useNavFooterLocales();
  const { tiktok, youtube, whatsApp, telegram, discord, instagram, facebook, twitter } =
    useSocialInfo();
  // -----------------------RENDER
  return (
    <footer className={classNames}>
      <Frow hAlign="center" vAlign="middle">
        <Fcol span={100}>
          <Frow className="linksCont" hAlign="center" vAlign="middle">
            <Fcol className="pinkRightBorder" {...boolResponsiveProps(25, 33)}>
              <LinkCustom href="/contact">{contactLink.text}</LinkCustom>
            </Fcol>
            <Fcol className="pinkRightBorder" {...boolResponsiveProps(25, 33)}>
              <LinkCustom href="/help">{helpLink.text}</LinkCustom>
            </Fcol>
            <Fcol {...boolResponsiveProps(25, 33)}>
              <LinkCustom href="/faqs">{faqLink.text}</LinkCustom>
            </Fcol>
          </Frow>
        </Fcol>
        <Fcol>
          <Divider>
            <section>
              <a href={tiktok.url} target="_blank" rel="noopener noreferrer">
                <FaTiktok />
              </a>
              <a href={youtube.url} target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </a>
              <a href={whatsApp.url} target="_blank" rel="noopener noreferrer">
                <FaWhatsapp />
              </a>
              <a href={telegram.url} target="_blank" rel="noopener noreferrer">
                <FaTelegramPlane />
              </a>
            </section>

            <section>
              <a href={discord.url} target="_blank" rel="noopener noreferrer">
                <FaDiscord />
              </a>
              <a href={instagram.url} target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
              <a href={facebook.url} target="_blank" rel="noopener noreferrer">
                <FaFacebookSquare />
              </a>
              <a href={twitter.url} target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
            </section>
          </Divider>
        </Fcol>
        <Fcol {...responsiveBasicGrid(33)}>
          <LinkCustom href="/privacy-policy">{privPolLink.text}</LinkCustom>
        </Fcol>
        <Fcol {...responsiveBasicGrid(33)}>
          <ForgeBrandThemed />
          <h3>{`Copyright © ${COMPANY_YEAR} Forge.`}</h3>
        </Fcol>
        <Fcol {...responsiveBasicGrid(33)}>
          <LinkCustom href="/service-terms">{serviceTermsLink.text}</LinkCustom>
        </Fcol>
      </Frow>
    </footer>
  );
}
