export const locales = {
  english: {
    Buttons: {
      home: 'Home',
      prods: 'Products / Services',
      pricing: 'Pricing',
      contact: 'Contact',
      blog: 'Community',
    },
    modal: {
      loginTittle: 'Log in',
      recoveryPass: 'Password Recovery',
    },
    NavLogin: {
      submitButton: 'Access',
      notSignYet: "Don't have an account yet?",
      forgotPass: 'Forgot your password?',
      googleSso: 'Sign in with Google',
      facebookSso: 'Sign in with Facebook',
      loginFormLabels: {
        email: {
          label: 'Email',
        },
        password: {
          label: 'Password',
        },
        fullName: {
          label: undefined,
        },
        authType: {
          label: undefined,
        },
      },
    },
    NavLoginRecovery: {
      successSwal: 'Password recovery email has been sent.',
      submitButton: 'Send Recovery Email',
      notSignYet: "Don't have an account yet?",
      goToLogin: 'Access your account',
      loginFormLabels: {
        email: {
          label: 'Email',
        },
      },
    },
  },
  spanish: {
    Buttons: {
      home: 'Inicio',
      prods: 'Productos / Servicios',
      pricing: 'Precios',
      contact: 'Contacto',
      blog: 'Comunidad',
    },
    modal: {
      loginTittle: 'Inicia Sesión',
      recoveryPass: 'Recuperar Contraseña',
    },
    NavLogin: {
      submitButton: 'Acceder',
      notSignYet: '¿No tienes cuenta aún?',
      forgotPass: '¿Olvidaste tu contraseña?',
      googleSso: 'Inicio de sesión con Google',
      facebookSso: 'Inicio de sesión con Facebook',
      loginFormLabels: {
        email: {
          label: 'Correo',
        },
        password: {
          label: 'Contraseña',
        },
        fullName: {
          label: undefined,
        },
        authType: {
          label: undefined,
        },
      },
    },
    NavLoginRecovery: {
      successSwal: 'Se envió correo de recuperación de contraseña',
      submitButton: 'Enviar correo de recovery',
      notSignYet: '¿No tienes cuenta aún?',
      goToLogin: 'Accede a tu cuenta',
      loginFormLabels: {
        email: {
          label: 'Correo',
        },
      },
    },
  },
} as const;

export type LocaleData = (typeof locales)['english'];
