import { clearAuthToken } from 'src/utils/functions/localStorageUtils';
import { useUserInfoStore } from './store';

export function useLogOut() {
  const { clear } = useUserInfoStore();

  function logOut() {
    clearAuthToken();
    clear();
  }
  return { logOut };
}
