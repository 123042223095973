// ---Dependencys
import { GEN_ALT } from 'AppConfig/globalConfig';
import Image from 'next/image';
import { type CSSProperties, type ReactElement } from 'react';

// ---Props Types
interface Props {
  src: string;
  className?: string;
  style?: CSSProperties;
  alt?: string;
  width?: number;
  height?: number;
}

/** Contiene el tag img dentro de un div para controlar la imagen con más precisión, setea un alt por defecto en caso de no contar con uno */
export function Img(props: Props): ReactElement {
  const { width, height, src, alt, style, ...className } = props;
  return (
    <div style={style} {...className}>
      <Image
        src={src}
        alt={alt || GEN_ALT}
        width={width || 900}
        height={height || 900}
        style={{ aspectRatio: 'auto' }}
        priority
      />
    </div>
  );
}
