// ---Dependencies
import { type ReactElement } from 'react';
import { useRouter } from 'next/router';
// ---UI Dependencies
import { Button, Divider } from 'antd';
import { Fcol, Frow } from 'react-forge-grid';
// ---Locales
import { useNavbarLocales } from '../../../locales/useNavbarLocales';
import { useCommonLocales } from 'src/appConfig/locales/common/useCommonLocales';
// ---Config

import { useControlLoginModal } from 'Utils/hooks/app/useControlLoginModal';
import { useLocaleLink } from 'Utils/hooks/app/useLocaleLink';
// ---Components
import { RecoveryForm } from './RecoveryForm/RecoveryForm';

/**
 * NavLoginRecovery Component:  Descripcion del comportamiento...
 * @returns {ReactElement}
 */
export function NavLoginRecovery(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { notSignYet, goToLogin } = useNavbarLocales().NavLoginRecovery;
  const { orExclusive } = useCommonLocales();
  const { setShowFalse, setScreenTypeValue } = useControlLoginModal();
  const localeLinks = useLocaleLink('/signup');
  const router = useRouter();
  // -----------------------MAIN METHODS
  /** */
  function navigateToSignup() {
    router.push({
      pathname: localeLinks?.['/signup'],
    });
    setShowFalse();
  }
  /** */
  function navigateToRecovery() {
    setScreenTypeValue('login');
  }

  // -----------------------RENDER
  return (
    <Frow vSpace={5} hAlign="center">
      <Fcol span={100}>
        <RecoveryForm />
      </Fcol>
      <Fcol span={80}>
        <Button onClick={navigateToSignup} type="link">
          {notSignYet}
        </Button>
        <Divider>{orExclusive}</Divider>
        <Button onClick={navigateToRecovery} type="link">
          {goToLogin}
        </Button>
      </Fcol>
    </Frow>
  );
}
