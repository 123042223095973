// ---Dependencies
import { type AppType } from 'next/app';
// ---Services
import { api } from 'Services/config/api';
// ---Components
import { GlobalProvider } from 'GlobalProvider/GlobalProvider';
import { GlobalLayout } from 'GlobalLayout/GlobalLayout';
// ---Styles
import 'Styles/_index.scss';

const MyApp: AppType = ({ Component, pageProps }) => {
  return (
    <>
      <GlobalProvider>
        <GlobalLayout>
          <Component {...pageProps} />
        </GlobalLayout>
      </GlobalProvider>
    </>
  );
};

// MyApp.getInitialProps = async (appContext: AppContext) => {
//   const { Component, ctx } = appContext;
//   let pageProps = {};

//   if (Component.getInitialProps) {
//     pageProps = await Component.getInitialProps(ctx);
//   }

//   return { pageProps };
// };

const WithTRCP = api.withTRPC(MyApp);

export default WithTRCP;
