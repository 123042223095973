export interface CurrentStyles {
  layoutStyle: string;
  headerStyle: string;
  contentStyle: string;
  footerStyle: string;
}

/** Genera el selector de clase completo para una page */
export function selectorLayoutBuilder({
  container,
  isMovil,
  theme,
}: {
  container?: string;
  theme: string;
  isMovil: boolean;
}) {
  const screenType = isMovil ? 'mobile' : 'desktop';

  const defaultTheme = `GlobalLayout GlobalLayout-${screenType} GlobalLayout-${theme}`;
  const withContainer = `${container} ${container}-${screenType} ${container}-${theme}`;
  return container ? withContainer : defaultTheme;
}

/** Genera el selector de clase completa un contenedor específico que considera tema y tamaño de pantalla  */
export function selectorBuilder({
  container,
  isMovil,
  theme,
}: {
  container?: string;
  theme: string;
  isMovil: boolean;
}) {
  if (!container) return '';
  const screenType = isMovil ? 'mobile' : 'desktop';
  return `${container} ${container}-${screenType} ${container}-${theme}`;
}

/** Genera el selector de clase completa para vincular un hijo a un contenedor específico que considera tema y tamaño de pantalla  */
export function selectorChildBuilder({
  child,
  isMovil,
  theme,
  container,
}: {
  container?: string;
  child: string;
  theme: string;
  isMovil: boolean;
}) {
  if (!container) return '';
  const screenType = isMovil ? 'mobile' : 'desktop';
  return `${container}-${child} ${container}-${child}-${screenType} ${container}-${child}-${theme} ${child} ${child}-${screenType} ${child}-${theme}`;
}
