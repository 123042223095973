// ---Dependencies
import Script from 'next/script';
import { type ReactElement } from 'react';
import { ANALYTICS } from 'src/appConfig/globalConfig';

/**
 * GoogleTagManager Component:  Google Tag Manager script para añadir en el head de cada page que se requiera trackear
 * @returns {ReactElement}
 */
export function GoogleTagManager({ children }: { children: ReactElement }): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  // -----------------------MAIN METHODS
  // -----------------------AUX METHODS
  // -----------------------RENDER
  return (
    <>
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          /** Google Tag Manager */
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${ANALYTICS.GA_TRACKING_ID}');
          /* End Google Tag Manager */
        `,
        }}
      />
      {children}
    </>
  );
}
