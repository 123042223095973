// ---Dependencies
import { type ReactElement, type ReactNode } from 'react';
import Link, { type LinkProps } from 'next/link';
// ---Custom Hooks
import { useGetLocal } from 'Utils/hooks/app/useGetLocal';

interface Props extends LinkProps {
  children: ReactNode;
}
/**
 * LinkCustom Component:  Extiende la funcionalidad de Link de nextJs pero concatena el lenguaje actual en el "href" como prefijo
 * @param {LinkProps} props - Parámetros de Link
 * @returns {ReactElement}
 */
export function LinkCustom(props: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { short } = useGetLocal();
  const newProps: LinkProps = {
    ...props,
    href: `/${short}${props.href}`,
  };
  // -----------------------RENDER
  return <Link {...newProps} />;
}
