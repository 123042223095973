// ---Dependencies
import { useRouter } from 'next/router';
// ---Config
import {
  SUPPORTED_LANGUAGES,
  SUPPORTED_LANGUAGES_SHORT,
  type ISupportedLanguageShort,
} from 'src/shared/constants/language';

/**
 * useGetLocal Custom Hook: Detecta y retorna la información del idioma de la url
 * @param {Props} props - Parámetros del componente como: ...
 */
export function useGetLocal() {
  // -----------------------CONSTS, HOOKS, STATES
  const currentPath = useRouter().asPath;
  // -----------------------MAIN METHODS
  const langPrefix = currentPath.substring(1, 3);

  const shortLocale: ISupportedLanguageShort = SUPPORTED_LANGUAGES_SHORT.includes(langPrefix)
    ? (langPrefix as ISupportedLanguageShort)
    : 'en';
  const locale = Object.values(SUPPORTED_LANGUAGES).filter((e) => e.short === shortLocale)[0];
  // -----------------------HOOK DATA
  return locale || SUPPORTED_LANGUAGES.english;
}
