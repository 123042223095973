// ---Dependencies
import { type ReactElement } from 'react';
import { useSelectorBuilder } from 'src/utils/hooks/styles/useSelectorBuilder';
// ---Styles
import style from './ShareModal.module.scss';
import { Modal } from 'antd';
import { useShareModal } from './config/useShareModal';
import { ForgeBrandThemed } from '../ForgeBrandThemed/ForgeBrandThemed';
import { useShareModalLocales } from './locales/useShareModalLocales';
import { CONTACT_PHONE_NO_PLUS } from 'src/appConfig/globalConfig';
import { FaDiscord, FaFacebookSquare, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import { SimpleDivider } from '../SimpleDivider/SimpleDivider';
import { useSocialInfo } from 'src/utils/hooks/app/useSocialInfo';

/**
 * ShareModal Component: Modal de contacto, redirige el usuario a nuestros medios de mensajería
 * @returns {ReactElement}
 */
export function ShareModal(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { classNames } = useSelectorBuilder(style.ShareModal);
  const { isOpen, variant, handleMethods } = useShareModal();
  const { title, message } = useShareModalLocales();
  const social = useSocialInfo();
  // -----------------------MAIN METHODS
  const preloadedMessage = encodeURIComponent(message[variant]);
  const whatsApp = `${social.whatsApp.url}&text=${preloadedMessage}`;
  const telegram = `${social.telegram.url}?start=${preloadedMessage}`;
  const facebook = social.facebook.url;
  const discord = social.discord.url;
  // -----------------------RENDER
  return (
    <Modal
      className={classNames}
      open={isOpen}
      onCancel={handleMethods.closeModal}
      footer={[<div />]}
    >
      <ForgeBrandThemed />
      <h5>{title}</h5>
      <SimpleDivider type="horizontal" variant="pink" />
      <section>
        <a className="telegram" href={telegram} target="_blank" rel="noopener noreferrer">
          <FaTelegramPlane />
        </a>
        <a
          className="whatsApp"
          data-action="open"
          data-phone={CONTACT_PHONE_NO_PLUS}
          data-message={preloadedMessage}
          href={whatsApp}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp />
        </a>
        <a className="facebook" href={facebook} target="_blank" rel="noopener noreferrer">
          <FaFacebookSquare />
        </a>
        <a className="discord" href={discord} target="_blank" rel="noopener noreferrer">
          <FaDiscord />
        </a>
      </section>
    </Modal>
  );
}
