import variables from './variables.module.scss';

/** Trae los "variables.scss" de la app, crashea (y debe crashear) si no carga los colores exitosamente */
export function getColors() {
  const allVariables = {
    colorPrimary1: variables.colorPrimary1 as string,
    colorPrimary2: variables.colorPrimary2 as string,
    colorPrimary3: variables.colorPrimary3 as string,
    colorPrimary4: variables.colorPrimary4 as string,
    colorPrimary5: variables.colorPrimary5 as string,
    colorPrimary6: variables.colorPrimary6 as string,
    colorPrimary7: variables.colorPrimary7 as string,
    colorPrimary: variables.colorPrimary as string,
    colorSecondary1: variables.colorSecondary1 as string,
    colorSecondary2: variables.colorSecondary2 as string,
    colorSecondary3: variables.colorSecondary3 as string,
    colorSecondary4: variables.colorSecondary4 as string,
    colorSecondary5: variables.colorSecondary5 as string,
    colorSecondary6: variables.colorSecondary6 as string,
    colorSecondary7: variables.colorSecondary7 as string,
    colorSecondary: variables.colorSecondary as string,
    colorThree1: variables.colorThree1 as string,
    colorThree2: variables.colorThree2 as string,
    colorThree3: variables.colorThree3 as string,
    colorThree4: variables.colorThree4 as string,
    colorThree5: variables.colorThree5 as string,
    colorThree6: variables.colorThree6 as string,
    colorThree7: variables.colorThree7 as string,
    colorThree: variables.colorThree as string,

    colorFour1: variables.colorFour1 as string,
    colorFour2: variables.colorFour2 as string,
    colorFour3: variables.colorFour3 as string,
    colorFour4: variables.colorFour4 as string,
    colorFour5: variables.colorFour5 as string,
    colorFour6: variables.colorFour6 as string,
    colorFour7: variables.colorFour7 as string,
    colorFour: variables.colorFour as string,

    colorMexPink1: variables.colorMexPink1 as string,
    colorMexPink2: variables.colorMexPink2 as string,
    colorMexPink3: variables.colorMexPink3 as string,
    colorMexPink4: variables.colorMexPink4 as string,
    colorMexPink5: variables.colorMexPink5 as string,
    colorMexPink6: variables.colorMexPink6 as string,
    colorMexPink7: variables.colorMexPink7 as string,
    colorMexPink: variables.colorMexPink as string,

    colorDark1: variables.colorDark1 as string,
    colorDark2: variables.colorDark2 as string,
    colorDark3: variables.colorDark3 as string,
    colorDark4: variables.colorDark4 as string,
    colorDark5: variables.colorDark5 as string,
    colorDark6: variables.colorDark6 as string,
    colorDark7: variables.colorDark7 as string,
    colorDark: variables.colorDark as string,
    colorLight1: variables.colorLight1 as string,
    colorLight2: variables.colorLight2 as string,
    colorLight3: variables.colorLight3 as string,
    colorLight4: variables.colorLight4 as string,
    colorLight5: variables.colorLight5 as string,
    colorLight6: variables.colorLight6 as string,
    colorLight7: variables.colorLight7 as string,
    colorLight: variables.colorLight as string,
    colorSuccess: variables.colorSuccess as string,
    colorWarning: variables.colorWarning as string,
    colorError: variables.colorError as string,
    headerHeight: variables.headerHeight as string,
    footerHeight: variables.footerHeight as string,
  };
  const keys = Object.keys(allVariables) as Array<keyof typeof allVariables>;
  keys.forEach((key) => {
    const value = allVariables[key];
    if (value === undefined) {
      throw new Error(`\n\nVariable ${key} variables.module.scss indefinida de \n\n`);
    }
  });
  return allVariables;
}
