export const locales = {
  english: {
    LoadingFullScreen: {
      loadingLabel: 'Loading',
    },
    orInclusive: 'or also',
    orExclusive: 'or could',
    orSimple: 'or',
  },
  spanish: {
    LoadingFullScreen: {
      loadingLabel: 'Cargando',
    },
    orInclusive: 'o también',
    orExclusive: 'o podrías',
    orSimple: 'o',
  },
} as const;

export type LocaleData = (typeof locales)['english'];
