// ---Custom Hooks
import { useAppInfoStoreV3 } from '@Redux/appInfo/store';

/**
 * useGlobalLoading Custom Hook: Rapido manejo de spinner de carga global en la aplicación
 */
export function useGlobalLoading() {
  // -----------------------CONSTS, HOOKS, STATES
  const { patch } = useAppInfoStoreV3();
  // -----------------------MAIN METHODS
  /** Trigerea spinner de carga global */
  function setLoadingTrue() {
    patch({ isLoading: true });
  }
  /** Oculta spinner de carga global */
  function setLoadingFalse() {
    patch({ isLoading: false });
  }
  /** Setea un valor para el spinner de carga global */
  function setLoadingValue(isLoading: boolean) {
    patch({ isLoading });
  }
  // -----------------------HOOK DATA
  return { setLoadingTrue, setLoadingFalse, setLoadingValue };
}
