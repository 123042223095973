// ---Dependencies
import { GoogleOAuthProvider } from '@react-oauth/google';
import { type ReactElement, type ReactNode } from 'react';
import { env } from 'src/env.mjs';

interface Props {
  children: ReactNode;
}
/**
 * GoogleAuth Component:  Provider que permite operaciones de autenticación con google
 * @param {Props} props - Parámetros del componente
 * @returns {ReactElement}
 */
export default function GoogleAuth({ children }: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const clientId = env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
  // -----------------------RENDER
  return <GoogleOAuthProvider clientId={clientId}>{children}</GoogleOAuthProvider>;
}
