export const SUPPORTED_LANGUAGES = {
  english: {
    short: 'en',
    friendlyShort: 'Eng',
    full: 'english',
    withRegion: 'en_US',
  },
  spanish: {
    short: 'es',
    friendlyShort: 'Esp',
    full: 'spanish',
    withRegion: 'es_419', // America latina code ISO 3166-1
  },
} as const;

export const SUPPORTED_LANGUAGES_KEYS = Object.keys(SUPPORTED_LANGUAGES);

export const SUPPORTED_LANGUAGES_SHORT = SUPPORTED_LANGUAGES_KEYS.map(
  (e) => SUPPORTED_LANGUAGES[e as 'english'].short,
) as string[];

export const SUPPORTED_LANGUAGES_FRIENDLY = SUPPORTED_LANGUAGES_KEYS.map(
  (e) => SUPPORTED_LANGUAGES[e as 'english'].friendlyShort,
) as string[];

export type ISupportedLanguage = keyof typeof SUPPORTED_LANGUAGES;

export type ISupportedLanguageShort =
  (typeof SUPPORTED_LANGUAGES)[keyof typeof SUPPORTED_LANGUAGES]['short'];

export type ISupportedLanguageFriendly =
  (typeof SUPPORTED_LANGUAGES)[keyof typeof SUPPORTED_LANGUAGES]['friendlyShort'];
