// ---Store Config
import { genericStore, storageBuilder } from '@Redux/config/genericStore';
// ---Types
import { type UserInfoInitial } from './storeTypes';

const initialState: UserInfoInitial = {
  basicInfo: undefined,
  credentials: undefined,
};

/** Instancia genérica básica  */
const useGenericZustand = genericStore({
  initialState,
  name: 'useUserInfoStore',
  persist: false,
});

/** Hook que manipula el storage de "useUserInfoStore */
export const useUserInfoStore = storageBuilder(initialState, useGenericZustand);
