// ---Store Config
import { genericStore, storageBuilder } from '@Redux/config/genericStore';
// ---Types
import { type UserPrefInitial } from './storeTypes';
// ---Actions
import { useCustomActions } from './actions';

const initialState: UserPrefInitial = {
  theme: 'light',
};

/** Instancia genérica básica  */
const useGenericZustand = genericStore({
  initialState,
  name: 'useUserPrefStore',
  persist: true,
});

/** Hook que manipula el storage de "useUserPrefStore */
export const useUserPrefStore = storageBuilder(initialState, useGenericZustand, useCustomActions);
