// ---Dependencies
import { type ReactElement } from 'react';
import { Fcol, Frow } from 'react-forge-grid';
// ---UI Dependencies
import { Drawer } from 'antd';
// ---CommonComponents
import { ForgeBrandThemed } from 'CComps/ForgeBrandThemed/ForgeBrandThemed';
import { Img } from 'CComps/Img/Img';
import { LinkCustom } from 'CComps/LinkCustom/LinkCustom';
import { TransparentButton } from 'CComps/TransparentButton/TransparentButton';
// ---Redux
import { useControlNavDrawer } from '@Redux/navDrawer/actions';
// ---Styles
import { useSelectorBuilder } from 'Utils/hooks/styles/useSelectorBuilder';
import { useNavbarLocales } from '../../locales/useNavbarLocales';
import { ThemeSwitch } from '../../common/ThemeSwitch/ThemeSwitch';
import { LangSelector } from '../../common/LangSelector/LangSelector';
import { NavLogWrapper } from '../../common/NavLogWrapper/NavLogWrapper';
import { NavButton } from '../../common/NavButton/NavButton';
import pricing from 'src/pages/en/pricing';
// ---Components

/** Componente NavDrawer:
 * Este componente renderiza un drawer en la parte izquierda de la pantalla, que se activa al presionar un botón hamburguesa en la barra de navegación.
 * @returns ReactElement - Renderiza un componente Drawer de AntD, que contiene botones de navegación, un selector de idioma, un switch de tema y un componente de inicio de sesión.
 */
export function NavDrawer(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { value, setDrawerFalse } = useControlNavDrawer();
  const { home, blog, pricing, prods } = useNavbarLocales().Buttons;
  const { classNames } = useSelectorBuilder('NavDrawer');
  // -----------------------RENDER
  return (
    <Drawer
      placement="left"
      onClose={setDrawerFalse}
      open={value}
      closable={false}
      size="default"
      className={classNames}
    >
      <TransparentButton border="none" className="hamburger-mobile" onClick={setDrawerFalse}>
        <Img src="/images/icons/icono-menu.webp" />
      </TransparentButton>
      <Frow vAlign="middle" hAlign="center">
        <Fcol>
          <LinkCustom href="/">
            <ForgeBrandThemed className="forge-spark-icon-mobile" />
          </LinkCustom>
        </Fcol>
        <Fcol span={33}>
          <ThemeSwitch />
        </Fcol>
        <Fcol span={33}>
          <LangSelector />
        </Fcol>
        <Fcol span={34}>
          <NavLogWrapper />
        </Fcol>
        <NavButton style={{ marginTop: '25px' }} className="NavButton-mobile" path="/">
          {home}
        </NavButton>
        <NavButton className="NavButton-mobile" path="/products-and-services">
          {prods}
        </NavButton>
        <NavButton className="NavButton-mobile" path="/pricing">
          {pricing}
        </NavButton>
        <NavButton className="NavButton-mobile" path="/forge-my-tech-community">
          {blog}
        </NavButton>
      </Frow>
    </Drawer>
  );
}
