import { env } from 'src/env.mjs';

export const PUBLIC_DOMAIN = env.NEXT_PUBLIC_DOMAIN;

export const PROD_FRONT_URL = 'https://www.forgemytech.com';

export const PUBLIC_FRONT_URL = env.NEXT_PUBLIC_FRONT_URL;

export const GEN_ALT = 'Forja tu camino hacia la innovación con la Inspiración de tus ideas';

export const CONTACT_PHONE = '+525517571017';

export const CONTACT_PHONE_NO_PLUS = '525517571017';

export const CONTACT_MAIL = 'forge.system@forgemytech.com';

export const ANALYTICS = {
  GA_TRACKING_ID: 'GTM-PTNWKZ6',
};

export const APPOINTMENT_COST = '$45.00 USD';

export const APPOINTMENT_PROMO_COST = '$0 USD';

export const SOCIAL = {
  whatsApp: {
    spanish: {
      webDesktop: `https://web.whatsapp.com/send?phone=${CONTACT_PHONE_NO_PLUS}`,
      webMobile:
        'https://api.whatsapp.com/send?phone=51924401004&text=¡Hola! PaskaPeru, necesito más información 🙌',
      web: `https://wa.me/${CONTACT_PHONE_NO_PLUS}`,
    },
    english: {},
  },
  telegram: {
    spanish: {
      web: `https://t.me/${CONTACT_PHONE}`,
    },
    english: {},
  },
  facebook: {
    spanish: {
      channelName: 'Forge My Tech',
      web: `https://www.facebook.com/ForgeMyTech`,
    },
    english: {},
  },
  discord: {
    spanish: {
      channelName: 'ForgeMyTech',
      web: `https://discord.gg/pjPp5H4afk`,
    },
    english: {},
  },
  tiktok: {
    spanish: {
      web: `https://www.tiktok.com/@forgemytech`,
    },
    english: {},
  },
  youtube: {
    spanish: {
      web: `https://www.youtube.com/channel/UCZXSYPlFz1EVyJN_8tdmqXA`,
    },
    english: {},
  },
  instagram: {
    spanish: {
      web: `https://www.instagram.com/forgemytech/`,
    },
    english: {},
  },
  twitter: {
    spanish: {
      web: `https://twitter.com/ForgeMyTech`,
    },
    english: {},
  },
};

export const SOCIAL_V2 = {
  english: {
    simpleContact: {
      phone: {
        plus: '+525517571017',
        noPlus: '525517571017',
        plusFormatted: '(+52) 55-1757-1017',
        noPlusFormatted: '(52) 55-1757-1017',
      },
      email: 'forge.system@forgemytech.com',
    },
    whatsApp: {
      desktopUrl: `https://web.whatsapp.com/send?phone=${CONTACT_PHONE_NO_PLUS}`,
      mobileUrl: `https://api.whatsapp.com/send?phone=${CONTACT_PHONE_NO_PLUS}`,
    },
    telegram: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://t.me/ForgeMyTech`,
      desktopUrl: `https://t.me/ForgeMyTech`,
    },
    facebook: {
      channelName: 'Forge My Tech',
      web: `https://www.facebook.com/ForgeMyTech`,
      mobileUrl: `http://m.me/ForgeMyTech`,
      desktopUrl: `https://www.facebook.com/ForgeMyTech`,
    },
    discord: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://discord.gg/pjPp5H4afk`,
      desktopUrl: `https://discord.gg/pjPp5H4afk`,
    },
    tiktok: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://www.tiktok.com/@forgemytech`,
      desktopUrl: `https://www.tiktok.com/@forgemytech`,
    },
    youtube: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://www.youtube.com/channel/UCZXSYPlFz1EVyJN_8tdmqXA`,
      desktopUrl: `https://www.youtube.com/channel/UCZXSYPlFz1EVyJN_8tdmqXA`,
    },
    instagram: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://www.instagram.com/forgemytech/`,
      desktopUrl: `https://www.instagram.com/forgemytech/`,
    },
    twitter: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://twitter.com/ForgeMyTech`,
      desktopUrl: `https://twitter.com/ForgeMyTech`,
    },
  },
  spanish: {
    simpleContact: {
      phone: {
        plus: '+525517571017',
        noPlus: '525517571017',
        plusFormatted: '(+52) 55-1757-1017',
        noPlusFormatted: '(52) 55-1757-1017',
      },
      email: 'forge.system@forgemytech.com',
    },
    whatsApp: {
      channelName: 'ForgeMyTech',
      desktopUrl: `https://web.whatsapp.com/send?phone=${CONTACT_PHONE_NO_PLUS}`,
      mobileUrl: `https://api.whatsapp.com/send?phone=${CONTACT_PHONE_NO_PLUS}`,
    },
    telegram: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://t.me/ForgeMyTech`,
      desktopUrl: `https://t.me/ForgeMyTech`,
    },
    facebook: {
      channelName: 'Forge My Tech',
      web: `https://www.facebook.com/ForgeMyTech`,
      mobileUrl: `http://m.me/ForgeMyTech`,
      desktopUrl: `https://www.facebook.com/ForgeMyTech`,
    },
    discord: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://discord.gg/pjPp5H4afk`,
      desktopUrl: `https://discord.gg/pjPp5H4afk`,
    },
    tiktok: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://www.tiktok.com/@forgemytech`,
      desktopUrl: `https://www.tiktok.com/@forgemytech`,
    },
    youtube: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://www.youtube.com/channel/UCZXSYPlFz1EVyJN_8tdmqXA`,
      desktopUrl: `https://www.youtube.com/channel/UCZXSYPlFz1EVyJN_8tdmqXA`,
    },
    instagram: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://www.instagram.com/forgemytech/`,
      desktopUrl: `https://www.instagram.com/forgemytech/`,
    },
    twitter: {
      channelName: 'ForgeMyTech',
      mobileUrl: `https://twitter.com/ForgeMyTech`,
      desktopUrl: `https://twitter.com/ForgeMyTech`,
    },
  },
};
