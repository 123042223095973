// ---Dependencies
import { useGetLocal } from 'Utils/hooks/app/useGetLocal';
// ---Config
import { locales, type LocaleData } from './locales';

/**
 * useCommonLocales Custom Hook: Locales para "labels comunes" detecta el idioma actual y retorna la variante de datos en el idioma correspondiente
 * @returns {LocaleData}
 */
export function useCommonLocales(): LocaleData {
  // -----------------------CONSTS, HOOKS, STATES
  const locale = useGetLocal().full;
  const currentLocal = locales[locale] as unknown as LocaleData;
  // -----------------------HOOK DATA
  return currentLocal;
}
