/**
 * Obtiene el valor de una propiedad anidada dentro de un objeto.
 *
 * @param {unknown} someObj - El objeto en el cual se buscará la propiedad anidada.
 * @param {string} propertyChain - La cadena de propiedades a buscar, por ejemplo: 'prop1.prop2.prop3'.
 * @returns {T | undefined} - Retorna el valor de la propiedad anidada o undefined si no se encuentra.
 *
 * @example
 * const labels = {
 *   prop1: {
 *     prop2: {
 *       prop3: 'some value',
 *     },
 *   },
 * };
 * getNestedValue<string>(labels, 'prop1.prop2.prop3'); // 'some value'
 */
export function getSimpleNestedValue<T>(someObj: unknown, propertyChain: string) {
  let value: unknown = someObj;
  if (typeof someObj === 'object' && someObj) {
    const properties = propertyChain.split('.');
    // Usando un bucle for
    for (const prop of properties) {
      if (value === null || value === undefined) {
        return undefined;
      }
      value = (value as Record<string, unknown>)[prop];
    }
    return value as T;
  }
  return undefined;
}

/**
 * Obtiene el valor de una propiedad anidada dentro de un objeto, también soporta acceder a elementos de arrays.
 *
 * @param {unknown} someObj - El objeto en el cual se buscará la propiedad anidada.
 * @param {string} propertyChain - La cadena de propiedades a buscar, por ejemplo: 'prop1.prop2.prop3[1]'.
 * @returns {T | undefined} - Retorna el valor de la propiedad anidada o undefined si no se encuentra.
 *
 * @example
 * const labels = {
 *   prop1: {
 *     prop2: {
 *       prop3: ['some value', 'another'],
 *     },
 *   },
 * };
 * getNestedValue<string>(labels, 'prop1.prop2.prop3[1]'); // 'another'
 */
export function getNestedValue<T>(someObj: unknown, propertyChain: string) {
  let value = someObj;
  if (typeof someObj === 'object' && someObj) {
    const properties = propertyChain.split(/\.|\[|\]\.?/).filter((p) => p);

    for (const prop of properties) {
      if (value === null || value === undefined) {
        return undefined;
      }

      const isIndex = /^\d+$/.test(prop);
      if (isIndex && Array.isArray(value)) {
        value = (value as unknown[])[parseInt(prop, 10)];
      } else {
        value = (value as Record<string, unknown>)[prop];
      }
    }
  }
  return value as T | undefined;
}
