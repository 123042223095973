// ---Store Config
import { genericStore, storageBuilder } from '@Redux/config/genericStore';
// ---Types
import { type NavDrawerInitial } from './storeTypes';

const initialState: NavDrawerInitial = {
  isOpen: false,
  isOpenProductCard: false,
};

/** Instancia genérica básica  */
const useGenericZustand = genericStore({
  initialState,
  name: 'useNavDrawerStore',
  persist: false,
});

/** Hook que manipula el storage de "useNavDrawerStore */
export const useNavDrawerStore = storageBuilder(initialState, useGenericZustand);
