// ---Dependencies
import { useEffect } from 'react';
// ---Utils
import { useWindowSize } from 'Utils/hooks/gen/useWindowSize';
// ---Redux
import { ResponsiveData } from '@Redux/appInfo/storeTypes';
import { useAppInfoStoreV3 } from '@Redux/appInfo/store';

/**
 * Hook para actualizar los puntos de interrupción de la pantalla en redux
 * con el tamaño de ventana actual usando un objeto ResponsiveData con
 * accesorios "isMovil" y "winSize"
 */
export function useScreenBreakpoints(): void {
  const { patch } = useAppInfoStoreV3();
  const { width } = useWindowSize();
  useEffect(() => updateScreen(), [width]);

  /** Calcula los puntos de interrupción de la pantalla y devuelve un objetoç
   *  ResponsiveData con propiedades "isMovil" y "winSize"
   * @returns {ResponsiveData}
   */
  function getScreen(): ResponsiveData {
    const caseXS = width < 576;
    const caseSM = width > 576 && width < 768;
    const caseMD = width > 768 && width < 992;
    const caseLG = width > 992 && width < 1200;
    const caseXL = width > 1200 && width < 1600;
    const caseXXL = width > 1600;
    if (caseXS) return { isMovil: true, winSize: 'xs' };
    if (caseSM) return { isMovil: true, winSize: 'sm' };
    if (caseMD) return { isMovil: false, winSize: 'md' };
    if (caseLG) return { isMovil: false, winSize: 'lg' };
    if (caseXL) return { isMovil: false, winSize: 'xl' };
    if (caseXXL) return { isMovil: false, winSize: 'xxl' };
    return { isMovil: false, winSize: 'lg' };
  }
  /** Actualiza la pantalla Breakpoints en redux */
  function updateScreen() {
    const newSize = getScreen();
    patch(newSize);
  }
}
