const generalDev = require('./profiles/generalDev');
const generalProd = require('./profiles/generalProd');

/**
 * Constante donde debes importar y asignar el PERFIL de envs que quieres para el ambiente de "Development"
 */
exports.devEnvs = generalDev;

/**
 * Constante donde debes importar y asignar el PERFIL de envs que quieres para el ambiente de "Production"
 */
exports.prodEnvs = generalProd;
