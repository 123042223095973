// ---Dependencys
import React, { createContext, type ReactElement, type ReactNode, useState } from 'react';
import { ConfigProvider, theme } from 'antd';
// ---Config
import { appColors } from 'AppConfig/globalData';
import { type UserPrefInitial } from 'src/redux/userPref/storeTypes';

interface Props {
  children: ReactNode;
}
type LocalTheme = UserPrefInitial['theme'];

export const AntdProviderContext = createContext({ changeAntdTheme: (_: LocalTheme) => {} });
/**
 * AntdProvider component: Proveedor de tema de antd, parra editar gama de colores
 * @returns {ReactNode}
 */
export default function AntdProvider({ children }: Props): ReactElement {
  const [localTheme, setLocalTheme] = useState<LocalTheme>('light');
  const primary = appColors?.colorPrimary || '#2db7f5';
  const algorithm = localTheme === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm;
  /** Cambia el algoritmo de tema de antd a dark o light */
  function changeAntdTheme(newAlgorithm: LocalTheme) {
    setLocalTheme(newAlgorithm);
  }
  return (
    <AntdProviderContext.Provider value={{ changeAntdTheme }}>
      <ConfigProvider
        theme={{
          algorithm,
          token: {
            colorPrimary: primary,
          },
        }}
      >
        {children}
      </ConfigProvider>
    </AntdProviderContext.Provider>
  );
}
