// ---Dependencies
import { type ReactElement } from 'react';
import { Button, type ButtonProps } from 'antd';
// ---Styles
import style from './TransparentButton.module.scss';
// ---UI Dependencies
// ---Custom Hooks
// ---Config
// ---Assets
// ---Utils
// ---Requests
// ---Styles
// ---CommonComponents
// ---Components
interface Props extends ButtonProps {
  border?: 'none' | 'transparent' | 'full';
}

/**
 * TransparentButton Component:  Descripcion del comportamiento...
 * @param {Props} props - Parametros del componente como: ...
 * @returns {ReactElement}
 */
export function TransparentButton({
  border = 'transparent',
  className,
  ...props
}: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const classNames = `${style.TransparentButton} ${style.TransparentButton}-${border} ${
    className || ''
  }`;
  // -----------------------RENDER
  return <Button {...props} className={classNames} />;
}
