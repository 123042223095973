// ---Dependencies
import { type KeyboardEvent, type ReactElement } from 'react';
// ---UI Dependencies
import { Input } from 'antd';
import { Fcol, Frow, type GridSystem, type FrowProps } from 'react-forge-grid';
// ---Custom Hooks
import { type FormikProps } from 'formik';
import { useAppInfoStoreV3 } from '@Redux/appInfo/store';

interface Props<T> extends Omit<FrowProps, 'children'> {
  labels?: { [key in keyof T]: { label?: string; placeholder?: string } };
  formik: FormikProps<T>;
  valueName: keyof T;
  labelGrid?: GridSystem;
  inputGrid?: GridSystem;
  required?: boolean;
  submitOnEnter?: boolean;
  disabled?: boolean;
}

/**
 * FBasicInput Component: Permite crear un "TextInput" dentro de un formulario de formik de una manera sencilla.
 * @param {Props} props - Parámetros del componente
 * @returns {ReactElement}
 */
export function FBasicInput<T>(props: Props<T>): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const {
    disabled,
    labels,
    formik,
    valueName,
    labelGrid,
    inputGrid,
    required,
    submitOnEnter,
    ...rowProps
  } = props;
  const { isMovil } = useAppInfoStoreV3(['isMovil']);

  const errMessage = formik.errors[valueName];
  const isError = !!errMessage && !!formik.touched[valueName];

  const newRowProps: FrowProps = {
    vAlign: 'top',
    hAlign: 'start',
    ...rowProps,
  };
  const newLabelGrid: GridSystem = {
    span: 30,
    ...labelGrid,
  };
  const newInputGrid: GridSystem = {
    span: 70,
    ...inputGrid,
  };
  const label = labels && labels[valueName].label?.length ? labels[valueName].label : undefined;
  const newLabel = required && label ? `*${label}` : label;
  const placeholder =
    labels && labels[valueName].placeholder?.length ? labels[valueName].placeholder : undefined;

  const currentStyle =
    newLabelGrid.span === 100 || (isMovil && (newLabelGrid.xs === 100 || newLabelGrid.sm === 100))
      ? { textAlign: 'start', padding: '5px 0px' }
      : { textAlign: 'end', padding: '5px 10px' };

  // -----------------------MAIN METHODS
  /** Función para hacer submit al presionar enter */
  function onKeyPress(event: KeyboardEvent<unknown>) {
    if (submitOnEnter && event.key === 'Enter') {
      formik.submitForm();
    }
  }
  // -----------------------RENDER
  if (label) {
    return (
      <Frow {...newRowProps} style={{ marginTop: '10px' }}>
        <Fcol {...newLabelGrid} style={currentStyle}>
          <span>{newLabel}:</span>
        </Fcol>
        <Fcol {...newInputGrid}>
          <Input
            disabled={disabled}
            value={String(formik.values[valueName] || '')}
            onChange={formik.handleChange(valueName)}
            onKeyPress={onKeyPress}
            status={isError ? 'error' : undefined}
            placeholder={placeholder}
          />
          {isError ? (
            <div className="ant-form-item-explain-error customHelper">{String(errMessage)}</div>
          ) : null}
        </Fcol>
      </Frow>
    );
  }
  return (
    <Frow {...newRowProps}>
      <Fcol span={100}>
        <Input
          disabled={disabled}
          value={String(formik.values[valueName] || '')}
          onChange={formik.handleChange(valueName)}
          onKeyPress={onKeyPress}
          status={isError ? 'error' : undefined}
          placeholder={placeholder}
        />
        {isError ? (
          <div className="ant-form-item-explain-error customHelper">{String(errMessage)}</div>
        ) : null}
      </Fcol>
    </Frow>
  );
}
