// ---Custom Hooks
import { useGetLocal } from './useGetLocal';
import { type Indexable } from 'src/shared/types/general';

/**
 * useLocaleLink Custom Hook: Transforma links en links con el idioma seleccionado
 * @param {string | string[]} links - Parámetros del componente como
 * @returns {ReactElement}
 */
export function useLocaleLink<T extends Indexable>(links: T | T[]) {
  // -----------------------CONSTS, HOOKS, STATES
  const { short } = useGetLocal();
  // -----------------------HOOK DATA
  if (Array.isArray(links)) {
    // T should be a specific string
    const map = new Map(links.map((item) => [item, `/${short}${String(item)}`]));
    return Object.fromEntries(map) as { [K in T]: string };
  }
  if (typeof links === 'string')
    // T should be a specific string
    return {
      [links]: `/${short}${links}`,
    } as {
        [k in T]: string;
      };
  return undefined;
}
