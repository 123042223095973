// ---Dependencies
import { type ReactElement } from 'react';
// ---UI Dependencies
import { Frow, Fcol } from 'react-forge-grid';
// ---Custom Hooks
import { useSelectorBuilder } from 'Utils/hooks/styles/useSelectorBuilder';
// ---Styles
import style from './Desktop.module.scss';
// ---CommonComponents
import { ForgeBrandThemed } from 'CComps/ForgeBrandThemed/ForgeBrandThemed';
import { LinkCustom } from 'CComps/LinkCustom/LinkCustom';
// ---Components
import { LangSelector } from '../common/LangSelector/LangSelector';
import { NavButton } from '../common/NavButton/NavButton';
import { ThemeSwitch } from '../common/ThemeSwitch/ThemeSwitch';
import { useNavbarLocales } from '../locales/useNavbarLocales';
import { NavLogWrapper } from '../common/NavLogWrapper/NavLogWrapper';
import { propSizePicker } from 'src/utils/functions/responsiveUtils';

/**
 * Desktop Component: Navbar en desktop incluye modal de formulario de login
 * @returns {ReactElement}
 */
export function Desktop(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { classNames } = useSelectorBuilder(style.Navbar);
  const { home, blog, pricing, prods } = useNavbarLocales().Buttons;
  // -----------------------RENDER
  return (
    <nav>
      <Frow vAlign="middle" hSpace={0} className={classNames}>
        <Fcol {...propSizePicker({ md: 14 }, 12)}>
          <LinkCustom href="/">
            <ForgeBrandThemed className="forge-spark-icon" />
          </LinkCustom>
        </Fcol>
        <Fcol {...propSizePicker({ md: 62 }, 64)}>
          <Frow vAlign="middle" hAlign="center" hSpace={5}>
            <NavButton path="/" {...propSizePicker({ md: 18 }, 18)}>
              {home}
            </NavButton>
            <NavButton path="/products-and-services" {...propSizePicker({ md: 44 }, 34)}>
              {prods}
            </NavButton>
            <NavButton path="/pricing" {...propSizePicker({ md: 17 }, 17)}>
              {pricing}
            </NavButton>
            <NavButton path="/forge-my-tech-community" {...propSizePicker({ md: 18 }, 18)}>
              {blog}
            </NavButton>
          </Frow>
        </Fcol>
        <Fcol {...propSizePicker({ md: 5 }, 6)}>
          <ThemeSwitch />
        </Fcol>
        <Fcol {...propSizePicker({ md: 12 }, 9)}>
          <LangSelector />
        </Fcol>
        <Fcol {...propSizePicker({ md: 7 }, 9)}>
          <NavLogWrapper />
        </Fcol>
      </Frow>
    </nav>
  );
}
