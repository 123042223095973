// ---Dependencies
import { type ReactElement, useContext, useEffect } from 'react';
// ---UI Dependencies
import { Switch } from 'antd';
import { FaSun, FaCloudMoon } from 'react-icons/fa';
import { ReactIcon } from 'CComps/ReactIcon/ReactIcon';
// ---Custom Hooks
import { useUserPrefStore } from '@Redux/userPref/store';
import { AntdProviderContext } from 'GlobalProvider/AntdProvider/AntdProvider';

/**
 * ThemeSwitch Component:  Swichea el tema de la aplicación
 * @param {Props} props - Parametros del componente como: ...
 * @returns {ReactElement}
 */
export function ThemeSwitch(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { patch, theme } = useUserPrefStore(['theme']);
  const { changeAntdTheme } = useContext(AntdProviderContext);
  const isLight = theme === 'light';
  // -----------------------MAIN METHODS
  /** Toggle theme */
  function toggleTheme() {
    const newtheme = isLight ? 'dark' : 'light';
    patch({ theme: newtheme });
  }
  useEffect(() => {
    changeAntdTheme(theme);
  }, [theme]);
  // -----------------------RENDER
  return (
    <Switch
      checkedChildren={<ReactIcon className="icon-switch" icon={FaSun} />}
      unCheckedChildren={<ReactIcon className="icon-switch" icon={FaCloudMoon} />}
      checked={isLight}
      onClick={toggleTheme}
      aria-label="Toggle light/dark theme"
    />
  );
}
