// ---Dependencies
import axios from 'axios';
import decode from 'jwt-decode';
import { useState } from 'react';
import { useGlobalLoading } from 'Utils/hooks/app/useGlobalLoading';
// ---UI Dependencies
// ---Custom Hooks
// ---Config
// ---Assets
// ---Utils
// ---Requests
// ---Styles
// ---CommonComponents
// ---Components

export interface GoogleDecodedInfo {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  email: string;
  email_verified: boolean;
  locale: string;
}
export interface GoogleJwtDecodedInfo {
  iss: string;
  nbf: number;
  aud: string;
  sub: string;
  email: string;
  email_verified: boolean;
  azp: string;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  iat: number;
  exp: number;
  jti: string;
}

const initialState = {
  loading: false,
  data: undefined as any,
  error: undefined as any,
};
interface State {
  loading: boolean;
  data?: GoogleDecodedInfo | GoogleJwtDecodedInfo;
  error?: unknown;
}
/**
 * useGoogleDecode Custom Hook: Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function useGoogleDecode() {
  // -----------------------CONSTS, HOOKS, STATES
  const [state, setState] = useState<State>(initialState);
  const { setLoadingFalse, setLoadingTrue } = useGlobalLoading();
  // -----------------------MAIN METHODS
  /** */
  function googleDecode(token?: string) {
    const url = `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`;
    setState({ ...state, loading: true });
    setLoadingTrue();
    axios
      .get(url)
      .then((response) => {
        const { data } = response as { data: GoogleDecodedInfo };
        setLoadingFalse();
        setState({ ...state, loading: false, data });
      })
      .catch((error) => {
        console.log('Error en googleDecode: ', error);
        setLoadingFalse();
        setState({ ...state, loading: false, error });
      });
  }
  /** */
  async function jwtDecode(token?: string) {
    try {
      setLoadingTrue();
      setState({ ...state, loading: true });
      const data = (await decode(token as string)) as GoogleJwtDecodedInfo;
      setLoadingFalse();
      setState({ ...state, loading: false, data });
    } catch (error) {
      console.log('Error en google jwtDecode: ', error);
      setLoadingFalse();
      setState({ ...state, loading: false, error });
    }
  }
  // -----------------------AUX METHODS
  // -----------------------HOOK DATA
  return { ...state, googleDecode, jwtDecode };
}
