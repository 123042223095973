// ---Custom Hooks
import { useGetLocal } from 'Utils/hooks/app/useGetLocal';
// ---Config
import { FormikHelpers, useFormik } from 'formik';
import { IRecoveryForm } from './definitions';
// ---Services
import { emailOnParamsYupSchema } from 'src/shared/utils/validations/commonValidations';
import { useMutationRequestRecovery } from 'src/services/auth/useMutationRequestRecovery';

/**
 * useFormLogic Custom Hook: Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function useFormLogic() {
  // -----------------------CONSTS, HOOKS, STATES
  // ---Form Stuff
  const locale = useGetLocal().full;
  const initialValues: IRecoveryForm = {
    email: '',
  };
  const formik = useFormik<IRecoveryForm>({
    initialValues,
    validationSchema: emailOnParamsYupSchema[locale],
    onSubmit,
  });
  // -----------------------Call Services
  const { mutate } = useMutationRequestRecovery(formik)
  // -----------------------MAIN METHODS
  async function onSubmit(values: IRecoveryForm, _: FormikHelpers<IRecoveryForm>) {
    mutate(values)
  }
  // -----------------------HOOK DATA
  return formik;
}
