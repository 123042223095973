// ---Dependencies
import { type ReactElement } from 'react';
import { Fcol, Frow } from 'react-forge-grid';
// ---Redux
import { useControlNavDrawer } from '@Redux/navDrawer/actions';
// ---Styles
import style from './Mobile.module.scss';
// ---CommonComponents
import { TransparentButton } from 'CComps/TransparentButton/TransparentButton';
import { Img } from 'CComps/Img/Img';
// ---Components
import { NavDrawer } from './NavDrawer/NavDrawer';
import { useSelectorBuilder } from 'src/utils/hooks/styles/useSelectorBuilder';
import { ThemeSwitch } from '../common/ThemeSwitch/ThemeSwitch';
import { LangSelector } from '../common/LangSelector/LangSelector';

/**
 * Mobile Component: NavBar para móviles
 * @returns {ReactElement}
 */
export function Mobile(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { setDrawerTrue } = useControlNavDrawer();
  const { classNames } = useSelectorBuilder(style.Mobile);
  // -----------------------RENDER
  return (
    <nav className={classNames}>
      <Frow vAlign="middle" hSpace={0} className={classNames}>
        <Fcol span={38}>
          <TransparentButton border="none" className="hamburger" onClick={setDrawerTrue}>
            <Img src="/images/icons/icono-menu.webp" />
          </TransparentButton>
          <NavDrawer />
        </Fcol>
        <Fcol span={31}>
          <ThemeSwitch />
        </Fcol>
        <Fcol span={31}>
          <LangSelector />
        </Fcol>
      </Frow>
    </nav>
  );
}
