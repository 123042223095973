// ---Dependencies
import { type ReactElement } from 'react';
// ---UI Dependencies
import { Fcol, Frow } from 'react-forge-grid';
import { Button } from 'antd';
import { IoMdLogIn } from 'react-icons/io';
// ---Hooks
// ---Types
import { type InternalLogin } from 'server/auth/declarations';
import { type FormikProps } from 'formik';
// ---CommonComponents
import { FBasicInput } from 'CComps/FormControll/FBasicInput/FBasicInput';
import { FBasicPassword } from 'CComps/FormControll/FBasicPassword/FBasicPassword';
import { responsiveBasicGrid } from 'Utils/functions/responsiveUtils';
import { useNavbarLocales } from 'src/GlobalLayout/NavbarSelector/Navbar/locales/useNavbarLocales';

interface Props {
  formik: FormikProps<InternalLogin>;
}

/**
 * LoginForm Component:  Componente que renderiza un formulario para iniciar sesión. Utiliza los inputs FBasicInput y FBasicPassword y traducciones proporcionadas por el control de "locales".
 * @param {Props} props - Parámetros del componente
 * @returns {ReactElement}
 */
export function LoginForm({ formik }: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { submitButton, loginFormLabels } = useNavbarLocales().NavLogin;
  // -----------------------RENDER
  return (
    <Frow vSpace={10} hAlign="center">
      <Fcol span={80}>
        <FBasicInput
          labelGrid={{ ...responsiveBasicGrid(30) }}
          inputGrid={{ ...responsiveBasicGrid(70) }}
          formik={formik}
          valueName="email"
          labels={loginFormLabels}
          submitOnEnter
        />
        <FBasicPassword
          labelGrid={{ ...responsiveBasicGrid(30) }}
          inputGrid={{ ...responsiveBasicGrid(70) }}
          formik={formik}
          valueName="password"
          labels={loginFormLabels}
          submitOnEnter
        />
      </Fcol>
      <Fcol span={80}>
        <Button
          className="LoginButton"
          type="primary"
          onClick={formik.submitForm}
          icon={<IoMdLogIn />}
        >
          {submitButton}
        </Button>
      </Fcol>
    </Frow>
  );
}
