// ---Dependencies
import { useEffect, useMemo, useState } from 'react';
// ---Store
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { devtools, persist } from 'zustand/middleware';
// ---Config
import { isBrowser } from 'AppConfig/globalData';
// ---Types
import {
  Actions,
  CustomActions,
  CustomHook,
  GenericStore,
  StorageConfig,
} from './genericStoreTypes';
// ---Utils
import { useIsomorphicLayoutEffect } from 'Utils/hooks/gen/useIsomorphicLayoutEffect';
import { getSimpleNestedValue } from 'src/utils/functions/otherUtils';
import { Indexable } from 'src/shared/types/general';

/** Boilerplate para crear un zustand store genérico con persistencia en localstorage si asi se configura y redux devtools */
export function genericStore<T>(config: StorageConfig<T>) {
  const { persist: persistStore, initialState, name } = config;
  if (persistStore) return create<GenericStore<T>>()(
    devtools(
      persist(
        (set) => ({
          ...initialState,
          // ---Actions
          patch: (data) => set((state) => ({ ...state, ...data })),
          nestedPatch: (key, data) => set((state) => ({ ...state, [key]: { ...(state as any)[key], ...data } })),
          deepNestedPatch: (propertyChain, data) => {
            set((state) => {
              const nestedObj = getSimpleNestedValue<Record<string, unknown>>(state, propertyChain);
              if (nestedObj) {
                const updatedNestedObj = { ...nestedObj, ...data };
                const properties = propertyChain.split('.');
                const rootKey = properties.shift();
                let result = state;

                if (rootKey) {
                  result = { ...result, [rootKey]: updatedNestedObj };

                  let currentLevel = (result as any)[rootKey];
                  for (const prop of properties) {
                    currentLevel[prop] = { ...currentLevel[prop], ...updatedNestedObj };
                    currentLevel = currentLevel[prop];
                  }
                }

                return result;
              }

              return state;
            });
          },
          update: (data) => set((state) => ({ ...state, ...data })),
          clear: () => set((state) => ({ ...state, ...initialState })),
        }),
        { name },
      ),
      { name, anonymousActionType: `${name} action` },
    ),
  );
  return create<GenericStore<T>>()(
    devtools(
      (set) => ({
        ...initialState,
        // ---Actions
        patch: (data) => set((state) => ({ ...state, ...data })),
        nestedPatch: (key, data) => set((state) => ({ ...state, [key]: { ...(state as any)[key], ...data } })),
        deepNestedPatch: (propertyChain, data) => {
          set((state) => {
            const nestedObj = getSimpleNestedValue<Record<string, unknown>>(state, propertyChain);
            if (nestedObj) {
              const updatedNestedObj = { ...nestedObj, ...data };
              const properties = propertyChain.split('.');
              const rootKey = properties.shift();
              let result = state;

              if (rootKey) {
                result = { ...result, [rootKey]: updatedNestedObj };

                let currentLevel = (result as any)[rootKey];
                for (const prop of properties) {
                  currentLevel[prop] = { ...currentLevel[prop], ...updatedNestedObj };
                  currentLevel = currentLevel[prop];
                }
              }

              return result;
            }

            return state;
          });
        },
        update: (data) => set((state) => ({ ...state, ...data })),
        clear: () => set((state) => ({ ...state, ...initialState })),
      }),
      { name, anonymousActionType: `${name} action` },
    ),
  );
}

type HookInstance<T> = ReturnType<typeof genericStore<T>>;

/** Constructor de hook de storage, retorna un custom hook que permite consumir y modificar estados de tu storage de manera  */
export const storageBuilder = <T, K>(
  initialState: T,
  useGenericZustand: HookInstance<T>,
  customActions?: CustomActions<T, K>,
): CustomHook<T, K> => {
  const partialState = {
    ...initialState,
    patch: (_: Partial<T>) => { },
    nestedPatch: (_k: keyof T, _: Partial<T>) => { },
    deepNestedPatch: (_k: string, _: Record<Indexable, any>) => { },
    clear: () => { },
    update: (_: T) => { },
  } as GenericStore<T>;

  return (stateProps) => {
    const zustanData = useGenericZustand((s) => s, shallow);
    const nextJSDepsArrary = stateProps?.map ? stateProps?.map((propName) => zustanData[propName]) : [];
    const [storageData, setStorageData] = useState<GenericStore<T>>(partialState);
    const depArray = stateProps?.map ? stateProps?.map((propName) => storageData[propName]) : [];
    const memorized = useMemo(() => storageData, depArray);
    useIsomorphicLayoutEffect(() => {
      if (isBrowser) {
        setStorageData(zustanData);
      }
    }, [isBrowser, ...nextJSDepsArrary, ...depArray]);
    // --------------CUSTOM ACTIONS-----------------
    const customAct = customActions ? customActions(storageData) : {};
    const actions = {
      patch: storageData?.patch,
      nestedPatch: storageData?.nestedPatch,
      deepNestedPatch: storageData?.deepNestedPatch,
      clear: storageData?.clear,
      update: storageData?.update,
      ...customAct,
    } as Actions<T, K>;
    // -----------------------HOOK DATA
    return { ...memorized, ...actions };
  };
};


export type CustomHookReturn<T, K> = GenericStore<T> & Actions<T, K>;
