/** Valida un id de mongo DB
 * @example
 * console.log(regex.test("64402fdf445ac40ec4a7e459")); // true
 * console.log(regex.test("1 202 304 1268")); // false
 */
export const validMongoId = /^[0-9a-fA-F]{24}$/;

/** Valida numeros de teléfono
 * @example
 * console.log(regex.test("12023041268")); // true
   console.log(regex.test("1 202 304 1268")); // false
   console.log(regex.test("+12023041268")); // false
 */
export const validPhoneWithCountry = /^\d{1,3}\d{10}$/;

/** Valida un string con "Bearer jwt"
 * @example
 * console.log(regex.test("Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjMzMTlkZjkxLWM5YTUtNGFlMS05M2JmLTBhMDBlNTM0OGU3OCIsIm5hbWUiOiJTdGV2ZW4gQmFycXVldCIsIm1haWwiOiJlYXN5QG1haWwuY29vbCIsInBhc3N3b3JkIjoiMTIzNDU2IiwiaWF0IjoxNjc1OTcwMDMwLCJleHAiOjEwMDAwMDE2NzU5NzAwMzB9.3eh__Tq2G9UD-0f5k8kJ-OlX2XXCcVqfQKDh0jghJ9Y")); // true
 * console.log(regex.test("Bearer jwt")); // false
 */
export const validBearerJwt = /^Bearer\s[\w-]+\.[\w-]+\.[\w-]+$/;

/** valiValida que la cadena sea un jwt
 * @example
 * console.log(validJwt.test("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IlN0ZXZlbiBCYXJxdWV0IiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c")); // true
 * console.log(validJwt.test("jwt")); // false
 */
export const validJwt = /^[\w-]+\.[\w-]+\.[\w-]+$/;

/** Valida que un string inicie con /admin
 * @example
 * const str1 = "/admin/dashboard";
 * const str2 = "/user/profile";
 *
 * console.log(isAdminPath.test(str1)); // true
 * console.log(isAdminPath.test(str2)); // false
 */
export const isAdminPath = /^\/admin/;

/** Valida que un string inicie con "/es/my-forge" o "/en/my-forge
 * @example
 * const str1 = "/es/my-forge";
 * const str2 = "/en/my-forge";
 * const str3 = "/fr/my-forge";
 *
 * console.log(regex.test(str1)); // true
 * console.log(regex.test(str2)); // true
 * console.log(regex.test(str3)); // false
 */
export const isPlatformPath = /^\/(?:es|en)\/my-forge/;
