// ---Types

import { useNavDrawerStore } from './store';

/** Acciones adicionales para tu "useNavDrawerStore" */
export const useControlNavDrawer = () => {
  const { patch, isOpen } = useNavDrawerStore(['isOpen']);
  // --------------CUSTOM ACTIONS-----------------
  /** */
  function setDrawerValue(newVal: boolean) {
    patch({ isOpen: newVal });
  }
  /** */
  function setDrawerTrue() {
    patch({ isOpen: true });
  }
  /** */
  function setDrawerFalse() {
    patch({ isOpen: false });
  }
  return {
    value: isOpen,
    setDrawerValue,
    setDrawerTrue,
    setDrawerFalse,
  };
};
