import { getColors } from './getColors';

/** Zona horaria del cliente en formato IANA */
export const CLIENT_TIME_ZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Chicago';

export const FRONT_NODE_ENV = process.env.NODE_ENV;

export const FRONT_IS_PROD = FRONT_NODE_ENV === 'production';

export const isBrowser = typeof window !== 'undefined';

export const healthPass = process?.env?.NEXT_PUBLIC_HEALTH_PASS;

export const appColors = getColors();

export const buttonStyles = (marginTopBot: number, round = false) => ({
  width: '96%',
  display: 'block',
  margin: `${marginTopBot}px auto`,
  borderRadius: round ? '50px' : '4px',
});

export const SUCCESS_MESSAGES = {
  title: {
    english: 'COMPLETED!',
    spanish: '¡COMPLETADO!',
  },
  genericMessage: {
    english: 'The process was completed successfully.',
    spanish: 'Se realizó el proceso de manera exitosa',
  },
};

export const WARNING_MESSAGES = {
  title: {
    english: 'CAUTION ADVISED',
    spanish: 'PRECAUCIÓN RECOMENDADA',
  },
  genericMessage: {
    english:
      "Please double-check your input and ensure your internet connection is stable before submitting your request. We're committed to helping you grow your business.",
    spanish:
      'Por favor, revisa tus datos y verifica que tu conexión a internet esté estable antes de enviar tu solicitud. Estamos comprometidos en ayudarte a hacer crecer tu negocio.',
  },
  genericShort: {
    english: 'CAUTION: Check input & connection',
    spanish: 'PRECAUCIÓN: Revisa datos y conexión',
  },
};

export const ERROR_MESSAGES = {
  title: {
    english: 'OPERATION FAILED',
    spanish: 'OPERACIÓN FALLIDA',
  },
  genericMessage: {
    english:
      "We're sorry, there was an issue processing your request. Please make sure your internet connection is stable and try again. We're here to help you take your business to the next level.",
    spanish:
      'Lo sentimos, hubo un problema al procesar tu solicitud. Por favor, asegúrate de que tu conexión a internet está estable y vuelve a intentarlo. Estamos aquí para ayudarte a llevar tu negocio al siguiente nivel.',
  },
};

export const CONFIRM_GENERIC_MESSAGES = {
  title: {
    english: 'Are you sure?',
    spanish: '¿Estás seguro?',
  },
  genericMessage: {
    english: 'The process cannot be reverted or could seriously change a functionality',
    spanish: 'El proceso no puede revertirse o podría cambiar seriamente una funcionalidad.',
  },
  confirm: {
    english: 'Yes, continue',
    spanish: 'Si, continuar',
  },
  finished: {
    english: 'Completed',
    spanish: 'Completado',
  },
};

export const DISCLAIMER_MESSAGES = {
  title: {
    english: 'Disclaimer',
    spanish: 'Aviso',
  },
  genericMessage: {
    english: 'The process will start',
    spanish: 'El proceso va a empezar',
  },
  confirm: {
    english: 'Ok',
    spanish: 'Ok',
  },
};
