// ---Dependencies
import { useLocaleLabels } from 'Utils/hooks/app/useLocaleLabels';
// ---Config
import { locales, type LocaleData } from './locales';

/**
 * useShareModalLocales Custom Hook: Locales para "ShareModalLocales" detecta el idioma actual y retorna la variante de datos en el idioma correspondiente
 * @returns {LocaleData}
 */
export function useShareModalLocales() {
  // -----------------------CONSTS, HOOKS, STATES
  const currentLocal = useLocaleLabels<LocaleData>(locales);
  // -----------------------HOOK DATA
  return currentLocal;
}
