// --- Dependency´s
import React, { type ReactElement, type ReactNode } from 'react';
// ---Custom Hooks
import { useLayoutStyles } from './config/useLayoutStyles';
import { FloatButton } from 'antd';
// ---Components
import { Layout } from 'antd';
import { LoadingFullScreen } from './LoadingFullScreen/LoadingFullScreen';
import { NavFooter } from './NavFooter/NavFooter';
import { NavbarSelector } from './NavbarSelector/NavbarSelector';
import { ShareModal } from 'src/common/ShareModal/ShareModal';
import { WhatsButtonFloat } from './WhatsButtonFloat/WhatsButtonFloat';

const { Header, Footer, Content } = Layout;

// -----------------------PROPS
interface Props {
  children: ReactNode;
}
/**
 * GlobalLayout Component: Componente que se representa globalmente en la aplicación y
 * persiste en todas las páginas. Puede agregar nuevos componentes globales de layout aquí para renderizarlos
 * globalmente. Ejemplo: Barras de herramientas, pies de página, componentes de chat, carritos, etc.
 * @param {Props} - Props of the component
 * @return {ReactElement} ReactElement
 */
export function GlobalLayout({ children }: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const classNames = useLayoutStyles();
  // -----------------------RENDER
  return (
    <div className={classNames}>
      <Layout>
        <Header>
          <NavbarSelector />
        </Header>
        <Content>
          {children}
          <ShareModal />
          <LoadingFullScreen />
          <FloatButton.BackTop />
          <WhatsButtonFloat />
        </Content>
        <Footer>
          <NavFooter />
        </Footer>
      </Layout>
    </div>
  );
}
