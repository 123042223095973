import { useAppInfoStoreV3 } from "src/redux/appInfo/store"


/**
 * useShareModal Custom Hook: Controla los valores del "ShareModal" 
 */
export function useShareModal() {
  // -----------------------CONSTS, HOOKS, STATES
  const { shareModal, nestedPatch } = useAppInfoStoreV3(['shareModal'])
  // -----------------------MAIN METHODS
  function setInterested() {
    nestedPatch('shareModal', { show: true, type: 'INTERESTED' })
  }
  function closeModal() {
    nestedPatch('shareModal', { show: false, type: 'INTERESTED' })

  }
  // -----------------------HOOK DATA
  return {
    isOpen: shareModal.show,
    variant: shareModal.type,
    handleMethods: {
      closeModal,
      setInterested,
    }
  }
}