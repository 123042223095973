// ---Dependencies
import { type ReactElement } from 'react';
import { useSelectorBuilder } from 'src/utils/hooks/styles/useSelectorBuilder';
// ---Styles
import style from './ProfileButton.module.scss';
import { TransparentButton } from 'src/common/TransparentButton/TransparentButton';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaUserCircle } from 'react-icons/fa';
import { useMyForgeNavbarLocales } from '../../locales/useMyForgeNavbarLocales';
import { useLogOut } from 'src/redux/userInfo/actions';
import { Button, Space, type MenuProps, Dropdown } from 'antd';
import { BiExit } from 'react-icons/bi';
import { LinkCustom } from 'src/common/LinkCustom/LinkCustom';

// ---Constants
const items: MenuProps['items'] = [
  {
    key: '1',
    label: <LogOutBtn />,
  },
  {
    key: '2',
    label: <MyAccount />,
  },
];

/**
 * ProfileButton Component:  Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function ProfileButton(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { classNames } = useSelectorBuilder(style.ProfileButton);
  // -----------------------MAIN METHODS
  // -----------------------AUX METHODS
  // -----------------------RENDER
  return (
    <div className={classNames}>
      <Dropdown menu={{ items }}>
        <a onClick={(e) => e.preventDefault()} href="#" title="My Profile Dropdown">
          <Space>
            <TransparentButton border="none" aria-label="Open my profile menu">
              <>
                <GiHamburgerMenu className="icon-ham" />
                <FaUserCircle className="icon-user" />
              </>
            </TransparentButton>
          </Space>
        </a>
      </Dropdown>
    </div>
  );
}

function LogOutBtn() {
  const { logOut } = useLogOut();
  const { signOut } = useMyForgeNavbarLocales();
  const { classNames } = useSelectorBuilder(style.ProfileButton);

  return (
    <div className={classNames}>
      <Button className="DropDownBtn" onClick={logOut} type="default" icon={<BiExit />}>
        {signOut}
      </Button>
    </div>
  );
}

function MyAccount() {
  const { myAccount } = useMyForgeNavbarLocales();
  const { classNames } = useSelectorBuilder(style.ProfileButton);

  return (
    <div className={classNames}>
      <LinkCustom href="/my-forge/my-account">
        <Button className="DropDownBtn" type="default">
          {myAccount}
        </Button>
      </LinkCustom>
    </div>
  );
}
