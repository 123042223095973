import * as yup from 'yup';
import { devEnvs, prodEnvs } from '../env/envsLoaded';

const currentEnvs = process.env.NODE_ENV === 'production' ? prodEnvs : devEnvs;

/**
 * Validaciones generadas automáticamente para envs de "env/envsLoaded" (valida valores string de longitud minima de 1 requeridos)
 * también puedes agregar validaciones para valores que vienen de fuera del control "env/envsLoaded" como alguna proveniente
 * unicamente de "process.env"
 */
const normalValidations = yup.object().shape({
  NODE_ENV: yup.string().min(1),
  ...yupPropBuilder(),
});

/**
 * Combina y sobrescribe las validaciones de "normalValidations", puedes validar patrones más complejos como expresiones regulares
 * en caso de que requieras para alguna de las envs.
 */
const override = yup.object().shape({
  NODE_ENV: yup.string().oneOf(['development', 'production']),
});

/** Valores de los envs a validar, si tienes agrega aquí envs de "process.env" que no están controlados por "env/envsLoaded" */
const processEnv = {
  ...currentEnvs,
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_HEALTH_PASS: process.env.NEXT_PUBLIC_HEALTH_PASS,
  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
  DB_URL: process.env.DB_URL,
  DB_NAME: process.env.DB_NAME,
  GOOGLE_OWNER: process.env.GOOGLE_OWNER,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
  GOOGLE_USER_ACCESS_TOKEN: process.env.GOOGLE_USER_ACCESS_TOKEN,
  GOOGLE_USER_REFRESH_TOKEN: process.env.GOOGLE_USER_REFRESH_TOKEN,
};

// Don't touch the part below
// --------------------------
const merged = normalValidations.concat(override);

/** @typedef {typeof processEnv} MergedInput */
/** @typedef {{[K in keyof MergedInput]: Exclude< MergedInput[K], undefined>;}} MergedOutput */

const validateEnvs = () => {
  try {
    const env = /** @type {MergedOutput} */ (
      merged.validateSync(processEnv, { abortEarly: false })
    );
    return env;
  } catch (error) {
    const yupError = error instanceof yup.ValidationError ? error?.errors : error;
    const errMessage = `\n\n❌ Attempted to undefined environment variable ${yupError}`;
    throw new Error(errMessage);
  }
};

let env = /** @type {MergedOutput} */ (processEnv);
if (!!process.env.SKIP_ENV_VALIDATION == false) {
  env = validateEnvs();
}

/**@returns {{[k:string]: yup.StringSchema}} */
function yupPropBuilder() {
  const keys = Object.keys(currentEnvs);
  /** @type {{[k: string]: yup.StringSchema}} */
  let validations = {};
  keys.forEach((prop) => {
    validations = {
      ...validations,
      [prop]: yup.string().min(1).required(),
    };
  });
  return validations;
}

export { env };
