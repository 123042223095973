// ---Custom Hooks
import { useGetLocal } from "src/utils/hooks/app/useGetLocal"

/**
 * useTrpcLocale Custom Hook: Genera un objeto de configuración para useQuery y useMutation con el idioma actual seteado
 */
export function useTrpcLocale() {
  // -----------------------CONSTS, HOOKS, STATES
  const { full } = useGetLocal()
  // -----------------------HOOK DATA
  return {
    trpc: {
      context: {
        locale: full,
      },
    },
  }
}

export const trcpSpanishConfig = {
  trpc: {
    context: {
      locale: "spanish",
    },
  },
} as const