// ---Custom Hooks
import { useAppInfoStoreV3 } from '@Redux/appInfo/store';
import { useUserPrefStore } from '@Redux/userPref/store';
// ---Utils
import { selectorChildBuilder } from 'Utils/functions/stylesUtils';

/**
 * useSelectorChildBuilder Custom Hook: Genera el selector de clase completa para vincular un hijo a un contenedor específico que detecta y cambia en tiempo real los css selectors para el tema y tamaño de pantalla en ejecución
 * @param {string} container - Nombre del contenedor al que se van a vincular los estilos
 * @returns {ReactElement}
 */
export function useSelectorChildBuilder({
  container,
  child,
}: {
  container?: string;
  child: string;
}) {
  // -----------------------CONSTS, HOOKS, STATES
  const { theme } = useUserPrefStore(['theme']);
  const { isMovil } = useAppInfoStoreV3(['isMovil']);
  const classNames = selectorChildBuilder({ container, child, theme, isMovil });
  // -----------------------HOOK DATA
  return { classNames };
}
