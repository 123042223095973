const { env } = require('process');
const { untrackedEnvs } = require('../untracked/secrets');

/**
 * - En la linea 5 está el ejemplo de como leer envs desde la terminal
 * - Todos los nombres deben llevar el prefijo "NEXT_PUBLIC_" para que la app pueda leer las envs
 */
module.exports = new (function () {
  // ---------------------------SHARED ------
  this.ENV_PROFILE = 'generalProd';
  this.DOMAIN = 'forgemytech.com';
  this.APP_URL = `https://${this.DOMAIN}`;
  // ---- Company Info
  this.COMPANY_ALT = 'Forja tu camino hacia la innovación con la Inspiración de tus ideas';
  this.COMPANY_NAME = 'ForgemMyTech';
  this.COMPANY_HOME_URL_ES = `${this.APP_URL}/es/my-forge`;
  this.COMPANY_HOME_URL_EN = `${this.APP_URL}/en/my-forge`;
  // ---------------------------BACKEND ------
  // ------ Config ------
  this.DB_NAME = env.DB_NAME || untrackedEnvs?.DB_NAME;
  this.DB_URL = env.DB_URL || untrackedEnvs?.DB_URL;
  /** Activa/Desactiva la validación de auth en endpoint/query */
  this.AUTH_ENABLED = 'true';
  // ------ STRIPE CONFIG ------
  this.STRIPE_SECRET_KEY = env.STRIPE_SECRET_KEY || untrackedEnvs?.STRIPE_SECRET_KEY;
  this.STRIPE_WH_SECRET = 'whsec_fTgWgTIXMjgT73AU9hIs3kRAFH68HcUF';

  this.SUCCESS_PAYMENT_PATH = `/my-forge/success-payment`;
  this.CANCEL_PAYMENT_PATH = `/my-forge/offers`;
  // ------ GOOGLE CONFIG ------
  this.GOOGLE_CLIENT_ID = env?.GOOGLE_CLIENT_ID || untrackedEnvs?.GOOGLE_CLIENT_ID;
  this.GOOGLE_CLIENT_SECRET = env?.GOOGLE_CLIENT_SECRET || untrackedEnvs?.GOOGLE_CLIENT_SECRET;
  /** Cuenta de google a la que pertenecen los tokens de acceso */
  this.GOOGLE_OWNER = env?.GOOGLE_OWNER || untrackedEnvs?.GOOGLE_OWNER;
  /** Para obtener accesos de usuario específico revisa https://bitbucket.org/steve-barquet/google-permissons-helper/src */
  this.GOOGLE_USER_ACCESS_TOKEN =
    env?.GOOGLE_USER_ACCESS_TOKEN || untrackedEnvs?.GOOGLE_USER_ACCESS_TOKEN;
  /** Para obtener accesos de usuario específico revisa https://bitbucket.org/steve-barquet/google-permissons-helper/src */
  this.GOOGLE_USER_REFRESH_TOKEN =
    env?.GOOGLE_USER_REFRESH_TOKEN || untrackedEnvs?.GOOGLE_USER_REFRESH_TOKEN;

  // ---------------------------FRONTEND ------
  this.NEXT_PUBLIC_PORT = '3000';
  this.NEXT_PUBLIC_DOMAIN = 'localhost';
  this.NEXT_PUBLIC_FRONT_URL = 'http://localhost:3000';
  this.NEXT_PUBLIC_APP_NAME = 'REACT APP DEVELOPMENT';
  /** Carga automáticamente las variables 'colors' de less como env */
  // ------ GOOGLE Services ------
  this.NEXT_PUBLIC_GOOGLE_CLIENT_ID =
    '163043205842-ppg3kb6r223au34sk0aqud4bim25tev7.apps.googleusercontent.com';
  // ------ FACEBOOK Services ------
  this.NEXT_PUBLIC_FACEBOOK_CLIENT_ID = '752373906317205';
})();
