// ---Dependencies
import { type ReactElement, type ReactNode } from 'react';
import { useRouter } from 'next/router';
// ---UI Dependencies
import { Fcol, type FcolProps } from 'react-forge-grid';
// ---Redux
import { useControlNavDrawer } from '@Redux/navDrawer/actions';
import { LinkCustom } from 'src/common/LinkCustom/LinkCustom';

interface Props extends FcolProps {
  children: ReactNode;
  path: string;
}

/**
 * NavButton Component: Anchor button styled para navegar dentro del navbar
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function NavButton({ children, path, ...grid }: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const currentPath = useRouter().asPath;
  const { setDrawerFalse } = useControlNavDrawer();
  // -----------------------MAIN METHODS
  const isCurrentPath =
    path === '/'
      ? false
      : (path === '/my-forge' &&
          (currentPath === '/es/my-forge' || currentPath === '/en/my-forge')) ||
        (path !== '/my-forge' && currentPath.includes(path));

  // -----------------------RENDER
  return (
    <Fcol {...grid} className={`nav-col ${grid.className}`}>
      <LinkCustom href={path} onClick={setDrawerFalse}>
        <div className={isCurrentPath ? 'nav-link nav-current' : 'nav-link'}>{children}</div>
      </LinkCustom>
      <div className="slider" />
    </Fcol>
  );
}
