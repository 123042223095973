// ---Dependencies
import { type ReactElement } from 'react';
// ---UI Dependencies
import { Modal } from 'antd';
import { FaUserCircle } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
// ---Utils
import { useSelectorChildBuilder } from 'Utils/hooks/styles/useSelectorChildBuilder';
import { useControlLoginModal } from 'Utils/hooks/app/useControlLoginModal';

// ---CommonComponents
import { TransparentButton } from 'CComps/TransparentButton/TransparentButton';
// ---Components
import { NavLoginForm } from './NavLoginForm/NavLoginForm';
import { NavLoginRecovery } from './NavLoginRecovery/NavLoginRecovery';
// ---Styles
import style from '../../Desktop/Desktop.module.scss';
import { ForgeBrandThemed } from 'src/common/ForgeBrandThemed/ForgeBrandThemed';
import { useUserInfoStore } from 'src/redux/userInfo/store';
import { LinkCustom } from 'src/common/LinkCustom/LinkCustom';
/**
 * NavLogWrapper Component:  Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function NavLogWrapper(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  // -----------------------CONSTS, HOOKS, STATES
  const { value, loginScreenType, setShowFalse, setShowTrue, setScreenTypeValue } =
    useControlLoginModal();
  const isLoginScreen = loginScreenType == 'login';
  const { classNames } = useSelectorChildBuilder({
    container: style.Navbar,
    child: 'NavLoginForm',
  });
  const { credentials } = useUserInfoStore(['credentials']);

  // -----------------------MAIN METHODS
  /** */
  function onCancel() {
    setScreenTypeValue('login');
    setShowFalse();
  }
  // -----------------------AUX METHODS
  // -----------------------RENDER
  if (credentials?.role === 'NORMAL')
    return (
      <>
        <LinkCustom href="/my-forge">
          <TransparentButton
            className="login-button"
            border="none"
            aria-label="Navigate to forgemytech.com platform"
          >
            <>
              <GiHamburgerMenu className="icon-ham" />
              <FaUserCircle className="icon-user" />
            </>
          </TransparentButton>
        </LinkCustom>
      </>
    );
  return (
    <>
      <TransparentButton
        onClick={setShowTrue}
        className="login-button"
        border="none"
        aria-label="Open login form"
      >
        <>
          <GiHamburgerMenu className="icon-ham" />
          <FaUserCircle className="icon-user" />
        </>
      </TransparentButton>
      <Modal className={classNames} open={value} onCancel={onCancel} footer={[<div />]}>
        <ForgeBrandThemed />
        {isLoginScreen ? <NavLoginForm /> : <NavLoginRecovery />}
      </Modal>
    </>
  );
}
