// ---Dependencies
import { useAppInfoStoreV3 } from '@Redux/appInfo/store';
import { useUserPrefStore } from '@Redux/userPref/store';
import { selectorLayoutBuilder } from 'Utils/functions/stylesUtils';


/**
 * UseLayoutStyles Custom Hook: Genera classnames dinamicas que responden al tamaño de pantalla y al tema para ser aplicados globalmente al layout
 * @returns {ReactElement}
 */
export function useLayoutStyles() {
  // -----------------------CONSTS, HOOKS, STATES
  const { theme } = useUserPrefStore(['theme']);
  const { isMovil, overideLayoutStyles } = useAppInfoStoreV3(['isMovil', 'overideLayoutStyles']);

  if (overideLayoutStyles) {
    return selectorLayoutBuilder({ container: overideLayoutStyles, theme, isMovil });
  }
  return selectorLayoutBuilder({ theme, isMovil });
}
