import { ISupportedLanguage } from 'src/shared/constants/language';
import * as yup from 'yup';

export function yupValidation(locale: ISupportedLanguage) {
  switch (locale) {
    case 'english':
      return yup.object({
        email: yup.string().email('Invalid email address').required('Email is required'),
        password: yup
          .string()
          .min(8, 'Password must be at least 8 characters long')
          .required('Password is required'),
      });

    case 'spanish':
      return yup.object({
        email: yup.string().email('Dirección de correo electrónico no válida').required('El correo electrónico es obligatorio'),
        password: yup
          .string()
          .min(8, 'La contraseña debe tener al menos 8 caracteres')
          .required('La contraseña es obligatoria'),
      });

    default:
      return yup.object({
        email: yup.string().email('Invalid email address').required('Email is required'),
        password: yup
          .string()
          .min(8, 'Password must be at least 8 characters long')
          .required('Password is required'),
      });
  }
}