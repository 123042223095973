// ---Dependencies
import { Button } from 'antd';
import Link from 'next/link';
import { type ReactElement } from 'react';
import { Fcol, Frow } from 'react-forge-grid';
import { propSizePicker } from 'src/utils/functions/responsiveUtils';
import { BiExit } from 'react-icons/bi';
// ---UI Dependencies
// ---Custom Hooks
// ---Config
// ---Assets
// ---Utils
import { useSelectorBuilder } from 'src/utils/hooks/styles/useSelectorBuilder';
import { ThemeSwitch } from '../Navbar/common/ThemeSwitch/ThemeSwitch';
// ---Requests
// ---Styles
import style from './AdminNavbar.module.scss';
import { ForgeBrandThemed } from 'src/common/ForgeBrandThemed/ForgeBrandThemed';
import { NavButton } from './NavButton/NavButton';
import { useLogOut } from 'src/redux/userInfo/actions';
import { useUserInfoStore } from 'src/redux/userInfo/store';
// ---CommonComponents
// ---Components
// ---Redux/Zustand
// ---Services

/**
 * AdminNavbar Component:  Descripción del comportamiento...
 * @param {Props} props - Parámetros del componente como: ...
 * @returns {ReactElement}
 */
export function AdminNavbar(): ReactElement | null {
  // -----------------------CONSTS, HOOKS, STATES
  const { classNames } = useSelectorBuilder(style.AdminNavbar);
  const { logOut } = useLogOut();
  const { credentials } = useUserInfoStore(['credentials']);
  // -----------------------MAIN METHODS
  // -----------------------AUX METHODS
  // -----------------------RENDER
  if (credentials?.role === 'ADMIN')
    return (
      <nav>
        <Frow vAlign="middle" hSpace={0} className={classNames}>
          <Fcol {...propSizePicker({ md: 14 }, 12)}>
            <Link href="/admin">
              <ForgeBrandThemed className="forge-spark-icon" />
            </Link>
          </Fcol>
          <Fcol {...propSizePicker({ xs: 50, md: 62 }, 64)}>
            <Frow vAlign="middle" hAlign="center" hSpace={5}>
              <NavButton path="/admin" {...propSizePicker({ md: 18 }, 18)}>
                Productos
              </NavButton>
              <NavButton path="/admin/citas" {...propSizePicker({ md: 18 }, 18)}>
                Citas
              </NavButton>
              <NavButton path="/admin/play" {...propSizePicker({ md: 18 }, 18)}>
                Play
              </NavButton>
            </Frow>
          </Fcol>
          <Fcol {...propSizePicker({ md: 10 }, 10)}>
            <ThemeSwitch />
          </Fcol>
          <Fcol {...propSizePicker({ md: 7 }, 7)}>
            <Button onClick={logOut} type="primary" icon={<BiExit />}>
              Sign out
            </Button>
          </Fcol>
        </Frow>
      </nav>
    );
  return null;
}
