// ---Store Config
import { genericStore, storageBuilder } from '@Redux/config/genericStore';
// ---Types
import { type AppInfoInitial } from './storeTypes';

const initialState: AppInfoInitial = {
  isMovil: false,
  winSize: 'lg',
  isLoading: false,
  overideLayoutStyles: undefined,
  showLogin: false,
  loginScreenType: 'login',
  shareModal: {
    show: false,
    type: 'INTERESTED',
  },
};

/** Instancia genérica básica  */
const useGenericZustand = genericStore({
  initialState,
  name: 'useAppInfoStoreV3',
});

/** Hook que manipula el storage de "useAppInfoStoreV3" Nota: El nombre tiene que se único */
export const useAppInfoStoreV3 = storageBuilder(initialState, useGenericZustand);
