// ---Custom Hooks
import { useGetLocal } from './useGetLocal';
// ---Config
import { type Indexable } from 'src/shared/types/general';

interface Locales {
  english: { [k: Indexable]: unknown };
  spanish: { [k: Indexable]: unknown };
}

/**
 * useLocaleLabels Custom Hook: Locales para "contenedor" detecta el idioma actual y retorna la variante de datos en el idioma correspondiente
 * @param {Props} props - Parámetros del hook como: ...
 */
export function useLocaleLabels<T>(locales: Locales) {
  // -----------------------CONSTS, HOOKS, STATES
  const locale = useGetLocal().full;
  const currentLocal = locales[locale] as unknown as T;
  // -----------------------HOOK DATA
  return currentLocal;
}
