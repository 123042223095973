import * as yup from 'yup';
import { validPhoneWithCountry } from 'Shared/utils/functions/regex';

/** Validaciones para MongoDB ObjectId en Yup id requerido y debe ser un formato válido de ObjectId */
export const mongoIdRequired = yup
  .string()
  .required('Id is required')
  .matches(/^[0-9a-fA-F]{24}$/, 'Invalid MongoDB ObjectId');

/** Validaciones para MongoDB ObjectId en Yup id requerido y debe ser un formato válido de ObjectId */
export const mongoIdRequiredEs = yup
  .string()
  .required('El ID es obligatorio')
  .matches(/^[0-9a-fA-F]{24}$/, 'Identificador de objeto MongoDB no válido');

/** Validaciones para MongoDB ObjectId en Yup id opcional y debe ser un formato válido de ObjectId */
export const mongoId = yup.string().matches(/^[0-9a-fA-F]{24}$/, 'Invalid MongoDB ObjectId');
export const mongoIdEs = yup
  .string()
  .matches(/^[0-9a-fA-F]{24}$/, 'Identificador de objeto de MongoDB no válido');

/** Validación de Yup para un número de teléfono. Se asegura de que el valor sea una cadena, sea opcional y cumpla con un formato de número de teléfono válido. */
export const phone = yup.string().matches(validPhoneWithCountry, 'Invalid phone number');

/** Validación de Yup para un número de teléfono. Se asegura de que el valor sea una cadena, sea requerido y cumpla con un formato de número de teléfono válido. */
export const phoneRequired = yup
  .string()
  .required('Phone is required')
  .matches(validPhoneWithCountry, 'Invalid phone number');

/** Valida que exista un id válido en los parámetros de la petición */
export const idOnParamsYupSchema = {
  trcpInput: yup
    .object({
      id: yup.string(),
    })
    .noUnknown(false),
  english: yup.object({
    id: mongoIdRequired,
  }),
  spanish: yup.object({
    id: mongoIdRequiredEs,
  }),
};

/** Valida que exista un id válido en los parámetros de la petición */
export const optionalAnyIdYupSchema = {
  trcpInput: yup
    .object({
      id: yup.string(),
    })
    .noUnknown(false),
  english: yup.object({
    id: yup.string(),
  }),
  spanish: yup.object({
    id: yup.string(),
  }),
};

/** Valida que exista un id válido en los parámetros de la petición */
export const requiredAnyIdYupSchema = {
  trcpInput: yup
    .object({
      id: yup.string(),
    })
    .noUnknown(false),
  english: yup.object({
    id: yup
      .string()
      .min(1, 'Parameter must have at least 1 character')
      .required('Parameter is required'),
  }),
  spanish: yup.object({
    id: yup
      .string()
      .min(1, 'El parámetro debe tener al menos 1 carácter')
      .required('El parámetro es requerido'),
  }),
};

export const emailOnParamsYupSchema = {
  trcpInput: yup
    .object({
      email: yup.string(),
    })
    .noUnknown(false),
  english: yup.object({
    email: yup.string().email('Invalid email address').required('Email is required'),
  }),
  spanish: yup.object({
    email: yup
      .string()
      .email('Dirección de correo electrónico no válida')
      .required('Correo electrónico es obligatorio'),
  }),
};

/**
 * Omite las propiedades especificadas de un esquema Yup.
 *
 * @param {yup.ObjectSchema} schema - El esquema Yup del que se deben omitir las propiedades.
 * @param {string[]} propertiesToOmit - Una lista de nombres de propiedades para omitir del esquema.
 * @returns {Record<string, yup.BaseSchema>} Un objeto con las propiedades omitidas del esquema original.
 *
 * @example
 * const schema = yup.object().shape({
 *   name: yup.string().required(),
 *   age: yup.number().required(),
 * });
 *
 * const objectWithoutAge = yup.object().shape({
 *   ...omitHelper(schema, ["age"]),
 *   age: yup.string().required(),
 * });
 */
export function omitYupProp<T extends yup.AnyObject, K extends keyof T>(
  schema: yup.ObjectSchema<T>,
  propertiesToOmit: K[],
) {
  const schemaFields = schema.fields;
  let newFields = {};
  const keys = Object.keys(schemaFields) as K[];

  keys.forEach((key) => {
    if (!propertiesToOmit.includes(key)) {
      newFields = { ...newFields, [key]: schemaFields[key] };
    }
  });
  type ReturnT = typeof schemaFields;
  return newFields as unknown as ReturnT;
}
