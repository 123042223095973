import {
  appColors,
  CONFIRM_GENERIC_MESSAGES,
  DISCLAIMER_MESSAGES,
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from 'AppConfig/globalData';
import { type ISupportedLanguage } from 'src/shared/constants/language';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/** Objeto Swal, permite activar alertas y notificaciones customizado para la aplicación */
export const AppSwal = withReactContent(
  Swal.mixin({
    confirmButtonColor: appColors.colorPrimary,
  }),
);

export function swalApiError(full: ISupportedLanguage, message?: string) {
  AppSwal.fire({
    title: ERROR_MESSAGES.title[full],
    text: message || ERROR_MESSAGES.genericMessage[full],
    icon: 'warning',
  });
}

export function swalApiSuccessAuto(full: ISupportedLanguage, message?: string) {
  AppSwal.fire({
    title: SUCCESS_MESSAGES.title[full],
    text: message || SUCCESS_MESSAGES.genericMessage[full],
    icon: 'success',
    timerProgressBar: true,
    timer: 5500,
  });
}

export function swalApiConfirmGeneric(
  full: ISupportedLanguage,
  callback: (() => void) | (() => Promise<void>),
  fireConfirm = true,
) {
  AppSwal.fire({
    title: CONFIRM_GENERIC_MESSAGES.title[full],
    text: CONFIRM_GENERIC_MESSAGES.genericMessage[full],
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: CONFIRM_GENERIC_MESSAGES.confirm[full],
  }).then(async (result) => {
    if (result.isConfirmed) {
      await callback();
      if (fireConfirm) {
        AppSwal.fire({
          title: CONFIRM_GENERIC_MESSAGES.finished[full],
          icon: 'success',
          timerProgressBar: true,
          timer: 1800,
        });
      }
    }
  });
}

export function swalApiConfirm({
  callback,
  full,
  fireConfirm = true,
  text,
  title,
  confirmText,
}: {
  full: ISupportedLanguage;
  callback: (() => void) | (() => Promise<void>);
  fireConfirm?: boolean;
  title?: string;
  text?: string;
  confirmText?: string;
}) {
  AppSwal.fire({
    title: title || CONFIRM_GENERIC_MESSAGES.title[full],
    text: text || CONFIRM_GENERIC_MESSAGES.genericMessage[full],
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: CONFIRM_GENERIC_MESSAGES.confirm[full],
  }).then(async (result) => {
    if (result.isConfirmed) {
      await callback();
      if (fireConfirm) {
        AppSwal.fire({
          title: confirmText || CONFIRM_GENERIC_MESSAGES.finished[full],
          icon: 'success',
          timerProgressBar: true,
          timer: 1800,
        });
      }
    }
  });
}

export function swalDisclaimer({
  callback,
  full,
  text,
  title,
}: {
  full: ISupportedLanguage;
  callback?: (() => void) | (() => Promise<void>);
  fireConfirm?: boolean;
  title?: string;
  text?: string;
}) {
  AppSwal.fire({
    title: title || DISCLAIMER_MESSAGES.title[full],
    text: text || DISCLAIMER_MESSAGES.genericMessage[full],
    icon: 'info',
    showCancelButton: true,
    confirmButtonText: DISCLAIMER_MESSAGES.confirm[full],
  }).then(async () => {
    await callback?.();
  });
}
