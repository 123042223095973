import { isBrowser } from 'AppConfig/globalData';

const AuthToken = 'AuthToken' as const;

/** Guarda un item en local storage */
export function setLocalStorageItem(key: string, value: unknown): void {
  try {
    if (isBrowser) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    console.log(`Error al guardar en ${key} en local storage:\n`);
  }
}
/** Extrae un valor del local storage */
export function getLocalStorageItem<T>(key: string): T | undefined {
  try {
    if (isBrowser) {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : undefined;
    }
    return undefined;
  } catch (error) {
    console.log(`Error al extraer ${key} de local storage:\n`);
    return undefined;
  }
}

/** Guarda el token de autenticación */
export function setAuthToken(token: string) {
  if (isBrowser) {
    setLocalStorageItem(AuthToken, token);
  }
}

/** Extrae el token de autenticación */
export function getAuthToken() {
  if (isBrowser) {
    return getLocalStorageItem<string>(AuthToken);
  }
  return undefined;
}

/** Limpia el token de autenticación */
export function clearAuthToken() {
  if (isBrowser) {
    setLocalStorageItem(AuthToken, '');
  }
}
