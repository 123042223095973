export const locales = {
  english: {
    pricingLink: {
      text: 'Pricing',
      description: 'Check out our pricing plans',
    },
    contactLink: {
      text: 'Contact',
      description: 'Contact us!',
    },
    helpLink: {
      text: 'Help',
      description: 'Check out our help section',
    },
    faqLink: {
      text: 'FAQs',
      description: 'Check out our frequently asked questions section',
    },
    privPolLink: {
      text: 'Privacy Policy',
      description: 'Check out our Privacy Policy section',
    },
    serviceTermsLink: {
      text: 'Service Terms',
      description: 'Check out our Service Terms section',
    },
  },
  spanish: {
    pricingLink: {
      text: 'Precios',
      description: 'Consulta nuestros planes de precios',
    },
    helpLink: {
      text: 'Ayuda',
      description: 'Consulta nuestra sección de ayuda',
    },
    contactLink: {
      text: 'Contacto',
      description: 'Contactanos',
    },
    faqLink: {
      text: 'Preguntas Frecuentes',
      description: 'Consulta nuestra sección de preguntas frecuentes',
    },
    privPolLink: {
      text: 'Políticas de Privacidad',
      description: 'Consulta nuestra sección de políticas de Privacidad',
    },
    serviceTermsLink: {
      text: 'Términos y Condiciones',
      description: 'Consulta nuestra sección de términos y Condiciones',
    },
  },
} as const;

export type LocaleData = (typeof locales)['english'];
