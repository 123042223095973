// ---Dependencies
import { type ReactElement } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
// ---UI Dependencies
import { TransparentButton } from 'CComps/TransparentButton/TransparentButton';
import { Dropdown, type MenuProps, Space } from 'antd';
import { TfiWorld } from 'react-icons/tfi';
// ---Config
import { toPascalCase } from 'src/shared/utils/functions/stringUtils';
import {
  type ISupportedLanguage,
  type ISupportedLanguageShort,
  SUPPORTED_LANGUAGES,
  SUPPORTED_LANGUAGES_SHORT,
} from 'Shared/constants/language';
// ---Styles
import { useSelectorChildBuilder } from 'Utils/hooks/styles/useSelectorChildBuilder';
import { useGetLocal } from 'src/utils/hooks/app/useGetLocal';
import style from '../../Desktop/Desktop.module.scss';

// ---Constants
const items: MenuProps['items'] = [
  {
    key: '1',
    label: <LangChanger lang="english" />,
  },
  {
    key: '2',
    label: <LangChanger lang="spanish" />,
  },
];

/**
 * LangSelector Component:  Permite cambiar de lenguaje la aplicación
 * @returns {ReactElement}
 */
export function LangSelector(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { classNames } = useSelectorChildBuilder({
    container: style.Navbar,
    child: 'LangSelector',
  });
  const { friendlyShort } = useGetLocal();
  // -----------------------RENDER
  return (
    <div className={classNames}>
      <Dropdown menu={{ items }}>
        <a onClick={(e) => e.preventDefault()} href="#" title="Change Language Dropdown">
          <Space>
            <div className="drop-element">
              <TfiWorld />
              <span>{friendlyShort}</span>
            </div>
          </Space>
        </a>
      </Dropdown>
    </div>
  );
}

/** Componente para cambiar lenguaje a uno especifico  */
function LangChanger({ lang }: { lang: ISupportedLanguage }) {
  const currentPath = useRouter().asPath;
  const { classNames } = useSelectorChildBuilder({
    container: `${style.Navbar}-LangSelector`,
    child: 'LangChanger',
  });

  const currentLang = currentPath.substring(1, 3); // es | en | string

  const shortLocale: ISupportedLanguageShort | undefined = SUPPORTED_LANGUAGES_SHORT.includes(
    currentLang,
  )
    ? (currentLang as ISupportedLanguageShort)
    : undefined;

  const newPath = shortLocale
    ? currentPath.replace(`/${shortLocale}`, `/${SUPPORTED_LANGUAGES[lang].short}`)
    : currentPath;

  return (
    <Link scroll={false} href={newPath}>
      <TransparentButton className={classNames} type="link">
        {toPascalCase(lang)}
      </TransparentButton>
    </Link>
  );
}
